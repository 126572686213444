import React from "react";
import styles from "./ImageCarouselItem.scss";
import VideoPlayer from "../../VideoPlayer";
import useSwipe from "Utils/hooks/useSwipe";
import IconRight from "../../../../assets/icons/icon-chevron-right-gray.svg";
import IconLeft from "../../../../assets/icons/icon-chevron-left-gray.svg";
import { getImageUrl } from "../../RowComponents/CommentRow/utils";

const ImageCarouselItem = ({ selectedItem, isMobile, setActiveDataIndex, itemLength }) => {
  const onLeftMove = () => {
    setActiveDataIndex(prev => (prev !== itemLength - 1 ? prev + 1 : 0));
  };
  const onRightMove = () => {
    setActiveDataIndex(prev => (prev !== 0 ? prev - 1 : itemLength - 1));
  };
  const onSwipe = swipeDirection => {
    if (swipeDirection === "left") onLeftMove();
    if (swipeDirection === "right") onRightMove();
  };
  const { onTouchStart, onTouchMove } = useSwipe(onSwipe);

  return (
    <div className={styles.root}>
      <img src={IconRight} alt='icon-right' className={styles.iconRight} onTouchStart={onLeftMove} />
      <img src={IconLeft} alt='icon-left' className={styles.iconLeft} onTouchStart={onRightMove} />
      <div className={styles.wrapper} onTouchStart={onTouchStart} onTouchMove={onTouchMove}>
        {selectedItem?.videoUrl ? (
          <VideoPlayer link={selectedItem.videoUrl} />
        ) : (
          <img
            className={styles.itemImage}
            src={getImageUrl(selectedItem?.url, !isMobile ? 481 : 327)}
            alt='active-img'
          />
        )}
      </div>
    </div>
  );
};

export default ImageCarouselItem;
