import React from "react";
import styles from "./CommentDetailModalMobile.scss";
import ImageCarousel from "../../../../ImageCarousel/ImageCarousel";
import CommentOwner from "../../Desktop/CommentDetailModal/CommentOwner/CommentOwner";
import CommentSection from "../../Desktop/CommentDetailModal/CommentSection/CommentSection";
import CommentProduct from "../../Desktop/CommentDetailModal/CommentProduct/CommentProduct";

const CommentDetailModalMobile = ({ comment, clickEvents }) => (
  <div className={styles.wrapper}>
    <ImageCarousel videos={comment.video} images={comment.media} isMobile />
    <CommentOwner comment={comment} />
    <CommentSection comment={comment} />
    <CommentProduct comment={comment} clickEvents={clickEvents} />
  </div>
);

export default CommentDetailModalMobile;
