import { useState } from "react";

const useGetElementHeight = (element) => {
  const [elementHeight, setElementHeight] = useState();

  if (typeof document === "undefined") return null;

  const target = document?.querySelector(element);

  new ResizeObserver(() => {
    const currentContentHeight = document?.querySelector(element);

    let style = getComputedStyle(currentContentHeight);
    setElementHeight(style.height);
  }).observe(target);
  return elementHeight;
};

export default useGetElementHeight;
