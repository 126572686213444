import React from "react";
import styles from "./CommentOwner.scss";
import { getProfilePicture } from "../../../../../RowComponents/CommentRow/utils";
import { IconStar } from "../../../../../../../assets/components";
import { getFormattedDate } from "Utils/helpers/date";
import IconVerifiedCheck from "../../../../../../../assets/icons/icon-verified-check.svg";
import Rate from "@cosmos/rate";

const CommentOwner = ({ comment }) => (
  <div className={styles.commentBadge}>
    <div className={styles.commentatorIcon}>
      <div className={styles.profileImg}>{getProfilePicture(comment.customer)}</div>
      {comment.isPurchaseVerified ? (
        <img src={IconVerifiedCheck} alt='verified-check' className={styles.verifiedCheck} />
      ) : null}
    </div>
    <div className={styles.commentatorInfo}>
      <div className={styles.commentator}>
        {comment.customer.name} {comment.customer.surname}
      </div>
      <div className={styles.ratingAndDate}>
        <div className={styles.rating}>
          <Rate value={comment.star} greyEmptyStar character={IconStar} size='small' disabled />
        </div>
        <div className={styles.date}>{getFormattedDate(comment.createdAt)}</div>
      </div>
    </div>
  </div>
);

export default CommentOwner;
