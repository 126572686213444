import React from "react";

const replaceBrandOnProductName = ({ brand, productName }) => {
  const brandName = `${brand?.toLocaleLowerCase("tr")} `;
  const checkBrandOnProductName = productName?.toLocaleLowerCase("tr")?.includes(brandName);

  if (checkBrandOnProductName) {
    const brandIndex = productName?.toLocaleLowerCase("tr").indexOf(brand?.toLocaleLowerCase("tr"));
    const productBrand = productName?.slice(brandIndex, brandIndex + brand?.length);
    const productNameWithBrand = productName?.replace(productBrand, "{{brand}}");

    return textReplace(productNameWithBrand, {
      brand: () => <span style={{ fontWeight: "700" }}>{productBrand}</span>
    });
  }
  return productName;
};
const textReplace = (text, values) => {
  const templateSplit = new RegExp(/{{(.*?)}}/g);
  const splitedText = text.split(templateSplit);

  return splitedText.map((item, index) => {
    if (values && values[item]) {
      return values[item](index);
    }
    return item;
  });
};
// eslint-disable-next-line import/prefer-default-export
export { replaceBrandOnProductName };
