import { useState, useEffect } from "react";

export default function useOnScreen(ref, callback, deps = []) {
  const [observer, setObserver] = useState(null);

  useEffect(() => {
    if (window) {
      setObserver(
        new IntersectionObserver(([entry]) => {
          if (entry?.isIntersecting) {
            callback();
          }
        })
      );
    }
  }, [...deps]);

  useEffect(() => {
    if (observer) {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [observer]);
}
