import apiManager from "./apiManager";
import { merchantExternalURL, merchantInternalURL } from "./urls";

export const getIsFollowingMerchant = (merchantId, apiClient, cookies) => {
  return apiManager(merchantExternalURL, apiClient, cookies).GET(`/isfollowing/${merchantId}`);
};

export const getIsFollowingMerchantInternal = (merchantId, apiClient, cookies, headers) => {
  return apiManager(merchantInternalURL, apiClient, cookies).GET(`/isfollowing/${merchantId}`, headers);
};

export const addFollowerToMerchant = (merchantId, apiClient, cookies) => {
  return apiManager(merchantExternalURL, apiClient, cookies).POST(`/followers/${merchantId}`);
};

export const removeFollowerFromMerchant = (merchantId, apiClient, cookies) => {
  return apiManager(merchantExternalURL, apiClient, cookies).DELETE(`/followers/${merchantId}`);
};

export const getFollowersCountOfMerchant = (merchantId, apiClient, cookies) => {
  return apiManager(merchantExternalURL, apiClient, cookies).GET(`/followers/count/${merchantId}`);
};

export const getFollowersCountOfMerchantInternal = (merchantId, apiClient, cookies) => {
  return apiManager(merchantInternalURL, apiClient, cookies).GET(`/followers/count/${merchantId}`);
};
