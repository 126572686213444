import React from "react";
import Button from "@cosmos/button";
import styles from "./StartedFollowModalMobile.scss";

const StartedFollowModal = ({ onCloseModal, isComFollowModal = false, isPagePDP }) => {
  return (
    <div>
      <section className={styles.modal}>
        <div className={styles.successImg}></div>
        <h1 className={styles.modalTitle}>Mağazayı takip etmeye</h1>
        <h1 className={styles.modalTitle}>başladın</h1>

        <span className={styles.desc}>
          Takipte olduğun mağazaları Listelerim sayfasında takip ettiklerim kısmında görebilirsin.
        </span>

        <div className={styles.modalButtonGroup}>
          <Button
            size={"large"}
            kind={isPagePDP ? "secondary" : "primary"}
            className='modalButton'
            as='a'
            onClick={onCloseModal}
          >
            <span className={styles.btnText}>{isComFollowModal ? "Tamam" : "Alışverişe devam et"}</span>
          </Button>
        </div>
      </section>
    </div>
  );
};

export default StartedFollowModal;
