import React from "react";
import Button from "@cosmos/button";
import Badge from "@cosmos/badge";
import styles from "./RatingInfoModalMobile.scss";
import DaVinciService from "../../../../../../services/davinciService";

const RatingInfoModal = ({ merchantDetail, onCloseModal }) => {
  const davinci = DaVinciService(merchantDetail, "Merchant Page");
  const onClickRatingCalculation = () => {
    davinci.clickRatingCalculationButtonEvent();
    onCloseModal();
  };

  return (
    <div>
      <section className={styles.modal}>
        <h1 className={styles.modalTitle}>Satıcı puanı</h1>

        <div className={styles.modalInfo}>
          {merchantDetail?.rating?.rating && (
            <Badge color={"#439E4A"} textColor={"#FFFFFF"} className={styles.badge}>
              {merchantDetail?.rating?.rating.toString().replace(".", ",")}
            </Badge>
          )}
          {!!merchantDetail?.rating?.feedbackCount && (
            <span className={styles.modalNumOfEvaluations}>{merchantDetail?.rating?.feedbackCount} değerlendirme</span>
          )}
        </div>
        <p className={styles.modalDesc}>
          Satıcı puanı, Hepsiburada.com müşterileri tarafından verilen zamanında gönderim, paketleme ve genel alışveriş
          deneyimi puanlarına göre hesaplanmaktadır. Tüm siparişler Hepsiburada güvencesi altındadır. Değerlendirmeler
          son 6 ay içerisinde yapılan değerlendirme sayısını vermektedir.
        </p>
        <div className={styles.modalButtonGroup}>
          <a href='https://www.hepsiburada.com/satici-puanlari-nasil-hesaplaniyor'>
            <Button onClick={onClickRatingCalculation} size={"large"} kind={"ghost"} className={styles.detailBtn}>
              Detaylar
            </Button>
          </a>
          <Button onClick={onCloseModal} size={"large"} kind={"primary"} className='modalButton'>
            Alışverişe devam et
          </Button>
        </div>
      </section>
    </div>
  );
};

export default RatingInfoModal;
