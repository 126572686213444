import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='1em' height='1em' fill='none' {...props}>
    <path
      fill={props.color || "#8C8C8C"}
      fillRule='evenodd'
      d='M12 7.987c.001-.294-.125-.574-.345-.768L6.708 2.86a.818.818 0 0 0-1.154.101.822.822 0 0 0 .073 1.131l4.325 3.813c.046.04.05.11.01.157l-4.335 3.823a.822.822 0 0 0-.102 1.156.818.818 0 0 0 1.154.102l4.974-4.385c.22-.194.347-.475.348-.77Z'
      clipRule='evenodd'
    />
  </svg>
);
export default SvgComponent;
