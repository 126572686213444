import React from "react";

export const onGoToProductClick = url => {
  window.open(url, "_blank");
};
export const onSellerNameClick = merchantUrl => {
  if (merchantUrl === "hepsiburada") return;
  window.open(`https://www.hepsiburada.com/magaza/${merchantUrl}`, "_blank");
};

export const onListNameClick = listUrl => {
  window.open(listUrl, "_blank");
};

const getNameInitials = customer => customer.name[0] + customer.surname[0];
export const getProfilePicture = customer => {
  if (customer?.profilePictureUrl) {
    return <img src={customer.profilePictureUrl} alt='profile-pic' />;
  }
  return <span>{getNameInitials(customer)}</span>;
};

export const getImageUrl = (url, s) => {
  const [baseUrl, imagePath] = url.split("/s/");
  const [number, size, id] = imagePath.split("/");
  const isQa = baseUrl.indexOf("qa-productimages") > -1;
  if (isQa) return url.replace("{size}", s);

  const newPath = "usercontent-images-";
  return `${baseUrl}/${newPath}${number}/${id}`;
};
