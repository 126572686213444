import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
    <path
      fill='#FFEFE5'
      d='M8 1.5A6.5 6.5 0 1 0 14.5 8 6.507 6.507 0 0 0 8 1.5ZM8 12a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Zm.5-3.045V9a.5.5 0 1 1-1 0v-.5A.5.5 0 0 1 8 8c.827 0 1.5-.563 1.5-1.25 0-.688-.673-1.25-1.5-1.25s-1.5.563-1.5 1.25V7a.5.5 0 0 1-1 0v-.25C5.5 5.51 6.621 4.5 8 4.5s2.5 1.01 2.5 2.25c0 1.086-.86 1.996-2 2.205Z'
    />
    <path
      fill='#FF6000'
      d='M8.75 11.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM8 4.5c-1.379 0-2.5 1.01-2.5 2.25V7a.5.5 0 1 0 1 0v-.25c0-.688.673-1.25 1.5-1.25s1.5.563 1.5 1.25C9.5 7.438 8.827 8 8 8a.5.5 0 0 0-.5.5V9a.5.5 0 1 0 1 0v-.045c1.14-.21 2-1.119 2-2.205C10.5 5.51 9.379 4.5 8 4.5Z'
    />
  </svg>
);
export default SvgComponent;
