import React from "react";
import { replaceBrandOnProductName } from "Utils/helpers/productName";
import OneStarRating from "../../../../../OneStarRating/OneStarRating";
import { onGoToProductClick } from "../../../../../RowComponents/CommentRow/utils";
import Button from "@cosmos/button";
import styles from "./CommentProduct.scss";

const CommentProduct = ({ comment, clickEvents }) => {
  const onButtonClick = () => {
    clickEvents.gotoProduct(comment, comment.index);
    onGoToProductClick(comment.product.url);
  };

  return (
    <div className={styles.productWrapper}>
      <div className={styles.productInfo}>
        <div className={styles.productIcon}>
          <img src={comment.product.imageUrl?.replace("{size}", 240)} alt='brand-icon' />
        </div>
        <div className={styles.product}>
          <div className={styles.productName}>
            {replaceBrandOnProductName({ brand: comment.product.brand, productName: comment.product.name })}
          </div>
          <OneStarRating
            rate={comment.product.average}
            classname={styles.productRating}
            totalRate={comment.product.userContentCount}
            showTotalRateText
          />
        </div>
      </div>
      <Button size='micro' kind='secondary' onClick={() => onButtonClick()}>
        Ürüne git
      </Button>
    </div>
  );
};

export default CommentProduct;
