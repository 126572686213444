/* eslint-disable react/no-unescaped-entities */
import React from "react";
import Button from "@cosmos/button";
import DaVinciService from "../../../../../../services/davinciService";
import { DAVINCI_PAGE_ORDER } from "../../../../../../utils/constants";
import styles from "./CouponSuccessModalMobile.scss";

const CouponSuccessModal = ({
  onCloseModal,
  merchantDetail,
  discountAmount,
  isComFollowModal = false,
  isPagePDP,
  isPageReviews,
}) => {
  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_ORDER);

  const viewCouponsHandler = () => {
    davinci.viewCouponsPageEvent(merchantDetail, false);
  };

  let buttonText = "";
  if (isPageReviews) {
    buttonText = "Alışverişe devam et";
  } else {
    if (isComFollowModal) {
      buttonText = "Tamam";
    } else {
      buttonText = "Alışverişe devam et";
    }
  }

  return (
    <div>
      <section className={styles.modal}>
        <div className={styles.greeting}></div>
        <h1 className={styles.modalTitle}>Tebrikler, {discountAmount} TL&apos;lik kuponun hesabında</h1>

        <ul className={styles.modalList}>
          <li>
            <div className={styles.modalListItem}>
              <span>•</span>

              <p className={styles.couponLinkInfo}>
                Kuponuna{" "}
                {isComFollowModal ? (
                  <a
                    onClick={viewCouponsHandler}
                    target='_blank'
                    href='https://kuponlarim.hepsiburada.com/'
                    rel='noreferrer'
                  >
                    "Kuponlarım"
                  </a>
                ) : (
                  " Kuponlarım"
                )}{" "}
                sayfasından ulaşabilirsin.
              </p>
            </div>
          </li>
          <li>
            <div className={styles.modalListItem}>
              <span>•</span>
              <p>Takipte olduğun mağazaları Listelerim sayfasında takip ettiklerim kısmında görebilirsin.</p>
            </div>
          </li>
        </ul>

        <div className={styles.modalButtonGroup}>
          <Button
            onClick={onCloseModal}
            size={"large"}
            kind={isPagePDP ? "secondary" : "primary"}
            className='modalButton'
            as='a'
          >
            {buttonText}
          </Button>
        </div>
      </section>
    </div>
  );
};

export default CouponSuccessModal;
