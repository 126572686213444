const ROUTE_PATHS = {
  FOLLOW: "/Follow",
  COMFOLLOW: "/ComFollow",
  // COMFOLLOWTEST: "/ComFollowTest",
  MERCHANTROW: "/MerchantRow",
  MERCHANTROWMOBILE: "/MerchantRowMobile",
  MERCHANTSEARCHBAR: "/MerchantSearchBar",
  ABOUTSELLER: "/AboutSeller",
  MERCHANT_TABULAR: "/MerchantTabular",
  MERCHANT_LAYOUT: "/MerchantLayout",
  MERCHANT_PROFILE: "/MerchantProfile",
  MERCHANT_HEADER: "/MerchantHeader",
};

export default ROUTE_PATHS;
