import React from "react";

function search() {
  return (
    <div>
      <svg width='17.14' height='17.14' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M4.98675 0.654353C0.825742 2.42319 -1.11429 7.2308 0.654378 11.3914C2.42319 15.5524 7.23081 17.4924 11.3914 15.7237C12.1684 15.3935 12.8679 14.9572 13.4803 14.439L18.8427 19.8022L18.9188 19.8679C19.1841 20.0648 19.5607 20.043 19.8014 19.8023C20.0662 19.5376 20.0662 19.1084 19.8015 18.8436L14.4387 13.48C16.3707 11.1942 16.9744 7.92868 15.7237 4.9867C13.9549 0.825611 9.14804 -1.11422 4.98675 0.654353ZM13.0578 12.983C14.9397 11.0701 15.5908 8.1395 14.476 5.5171L14.3825 5.30685C12.8366 1.98026 8.91836 0.45655 5.51711 1.9021C2.04519 3.378 0.426398 7.38956 1.90211 10.861C3.37801 14.3329 7.38956 15.9517 10.861 14.476C11.6756 14.1297 12.3882 13.6439 12.9827 13.0582C12.9942 13.0447 13.0063 13.0317 13.019 13.0189C13.0316 13.0064 13.0445 12.9944 13.0578 12.983Z'
          fill='#8C8C8C'
        />
      </svg>
    </div>
  );
}

export default search;
