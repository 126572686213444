import React, { useRef, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./BannerVideo.scss";
import ModalManager from "../ModalManager/ModalManager";
import Player from "./common/Player";
import MobilePlayer from "./common/MobilePlayer";
import useCheckMobileScreen from "../../../../../utils/hooks/useCheckMobileScreen";

const BannerVideo = ({
  source,
  poster,
  showModal,
  carousel,
  title,
  url,
  rowLength,
  clickEventHandler,
  products,
  rowIndex,
  bannerIndex,
  merchantDetail,
  row
}) => {
  const { isMobile } = useCheckMobileScreen();
  const videoModalRef = useRef(null);
  const [isFirstTime, setIsFirstTime] = useState(true);
  const handleShowModal = () => videoModalRef.current?.toggleModalVisibility(true, "videoBanner");

  return (
    <div
      className={cx({
        [styles.Container]: !carousel,
        [styles.CarouselContainer]: carousel
      })}
    >
      {!isMobile && (
        <>
          <span
            className={cx(styles.Title, {
              [styles.TitleHide]: !isFirstTime,
              [styles.MaxTitle]: rowLength < 3,
              [styles.MinTitle]: rowLength === 3
            })}
          >
            {title}
          </span>
          <Player
            title={title}
            url={url}
            source={source}
            poster={poster}
            showModal={showModal}
            onShowModal={handleShowModal}
            onShowTitle={setIsFirstTime}
            clickEvent={clickEventHandler}
            rowLength={rowLength}
            products={products}
            rowIndex={rowIndex}
            bannerIndex={bannerIndex}
            merchantDetail={merchantDetail}
            row={row}
          />
        </>
      )}
      {isMobile && (
        <>
          <span
            className={cx(styles.Title, {
              [styles.MaxTitle]: rowLength < 3,
              [styles.MinTitle]: rowLength === 3
            })}
          >
            {title}
          </span>
          <MobilePlayer
            title={title}
            url={url}
            source={source}
            poster={poster}
            showModal={showModal}
            onShowModal={handleShowModal}
            rowLength={rowLength}
            clickEvent={clickEventHandler}
            products={products}
            rowIndex={rowIndex}
            bannerIndex={bannerIndex}
            merchantDetail={merchantDetail}
            row={row}
          />
        </>
      )}
      <ModalManager
        ref={videoModalRef}
        device={isMobile ? "mobile" : "desktop"}
        source={source}
        poster={poster}
        title={title}
        url={url}
        rowLength={rowLength}
        products={products}
        rowIndex={rowIndex}
        bannerIndex={bannerIndex}
        merchantDetail={merchantDetail}
        row={row}
      />
    </div>
  );
};

BannerVideo.propTypes = {
  source: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  showModal: PropTypes.bool,
  carousel: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  rowLength: PropTypes.number,
  clickEventHandler: PropTypes.func
};
export default BannerVideo;
