import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
    <path
      fill='#fff'
      fillRule='evenodd'
      d='M2.165 2.165c.22-.22.576-.22.795 0L8 7.205l5.04-5.04c.2-.2.512-.218.732-.055l.063.055c.22.22.22.576 0 .795L8.795 8l5.04 5.04c.2.2.218.512.055.732l-.055.063a.562.562 0 0 1-.795 0L8 8.795l-5.04 5.04c-.2.2-.512.218-.732.055l-.063-.055a.562.562 0 0 1 0-.795L7.205 8l-5.04-5.04a.563.563 0 0 1-.055-.732l.055-.063Z'
      clipRule='evenodd'
    />
  </svg>
);
export default SvgComponent;
