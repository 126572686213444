/* eslint-disable */
// @todo should be component
function appendScriptTag(currentScript, callback) {
  const script = document.createElement("script");
  script.src = currentScript.src;
  script.async = true;
  script.onload = function () {
    if (callback) {
      callback();
    }
  };

  document.body.appendChild(script);
}

// @todo should be component
function appendLinkTag(style, onSuccess, onFailure) {
  const linkElement = document.createElement("link");

  linkElement.rel = style.rel;
  linkElement.href = style.href;
  linkElement.onload = function () {
    if (onSuccess) {
      onSuccess();
    }
  };
  linkElement.onerror = function () {
    if (onFailure) {
      onFailure();
    }
  };
  document.body.appendChild(linkElement);
}

// @todo should be component
function appendInlineScript(script) {
  const scriptElement = document.createElement("script");
  const inlineScript = document.createTextNode(script);

  scriptElement.appendChild(inlineScript);
  document.body.appendChild(scriptElement);
}

function appendScript(currentScript, callback) {
  const script = document.createElement("script");
  script.src = currentScript.src;

  document.body.appendChild(script);
}

const getAllMatches = (source, regex) => {
  const matches = [];
  source.replace(regex, function () {
    matches.push({
      match: arguments[0],
      offset: arguments[arguments.length - 2],
      groups: Array.prototype.slice.call(arguments, 1, -2),
    });
    return arguments[0];
  });
  return matches;
};

export { appendScriptTag, appendInlineScript, getAllMatches, appendLinkTag, appendScript };
