import qs from "query-string";

const URL_PARAMS_WHITE_LIST = ["sayfa", "filtreler", "q"];
const DOMAIN_WHITE_LIST = ["adservice"];

export const getEscapedUrlByWhiteListForGoogleBot = ({ url, isBot }) => {
  try {
    if (!isBot || !url || !typeof url === "string" || url.includes(DOMAIN_WHITE_LIST)) return url;

    const parsedUrl = qs.parseUrl(url, { sort: false });

    const queryParamsInUrl = parsedUrl?.query;
    const queryParamNames = Object.keys(queryParamsInUrl);

    if (queryParamNames?.length === 0) return url;

    const firstQueryParamName = queryParamNames?.[0];

    if (URL_PARAMS_WHITE_LIST.includes(firstQueryParamName)) {
      return url;
    }
    return parsedUrl?.url;
  } catch (error) {
    return url;
  }
};
