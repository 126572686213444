import React from "react";
import Button from "@cosmos/button";
import styles from "./CouponDealModalMobile.scss";

const CouponDealModalMobile = ({ onCloseModal, onClick, merchantDetail, customerDailyCouponsLimit, loading }) => {
  const {
    coupon: { discountAmount, endDate, couponName, includedCategoryIdList, includedSkuList, link },
    displayedName,
  } = merchantDetail;
  const beforeDot = discountAmount?.toString().split(".")[0];
  const afterDot = discountAmount?.toString().split(".")[1] || "00";

  return (
    <div className={styles.root}>
      <section className={styles.modal}>
        <h1 className={styles.modalTitle}>Kupon fırsatı</h1>
        <p className={styles.modalDescription}>Mağazayı hemen takip et, kuponun hesabına yüklensin!</p>

        <div className={styles.couponWrapper}>
          <div className={styles.couponLine}></div>
          <div className={styles.coupon}>
            <div className={styles.couponLeft}>
              <p className={styles.couponText}>{couponName}</p>
              <ul className={styles.couponInfo}>
                <li>
                  <span className={styles.couponInfoText}>Satıcı:</span>
                  <span className={styles.couponSeller}>
                    {displayedName.length > 20 ? displayedName.substr(0, 16) + "..." : displayedName}
                  </span>
                </li>
                <li>
                  <span className={styles.couponInfoText}>Son tarih:</span>
                  <span className={styles.couponInfoText}>
                    <strong>
                      {new Date(endDate).toLocaleDateString("tr-TR", {
                        month: "2-digit",
                        day: "2-digit",
                        year: "numeric",
                      })}
                    </strong>
                  </span>
                </li>
                {(includedCategoryIdList?.length > 0 || includedSkuList?.length > 0) && (
                  <li>
                    <a href={link} target='_blank' rel='noreferrer' className={styles.couponInfoText}>
                      Belirli ürünlerde geçerlidir.
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <div className={styles.couponRight}>
              <p className={styles.discountAmound}>{beforeDot}</p>
              <div className={styles.couponPriceChange}>
                <span>,{afterDot}</span>
                <span>TL</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.couponLimit}>
          {customerDailyCouponsLimit !== null && (
            <>
              <div className={styles.infoIcon}></div>
              <div>
                <span>{`Gün içinde kalan Takip et, kazan limitin: `} </span>
                <span>{customerDailyCouponsLimit}</span>
              </div>
            </>
          )}
        </div>

        <div className={styles.modalButtonGroup}>
          <a>
            <Button onClick={onCloseModal} size={"large"} kind={"ghost"} className={"detailButton"}>
              Vazgeç
            </Button>
          </a>
          <Button
            onClick={onClick}
            size={"large"}
            loading={loading ? loading.toString() : null}
            kind={"primary"}
            className='modalButton'
          >
            Takip et, kazan{" "}
          </Button>
        </div>
      </section>
    </div>
  );
};

export default CouponDealModalMobile;
