const MIN_SWIPE_PX = 5;

const useSwipe = (onSwipe, thresholdPX = MIN_SWIPE_PX) => {
  let xDown = null;
  let yDown = null;

  function getTouches(evt) {
    return (
      evt.touches || evt.originalEvent.touches // browser API
    ); // jQuery
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }

    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    const xDiff = xDown - xUp;
    const yDiff = yDown - yUp;
    const isThresholdExceeded = Math.abs(xDiff) >= thresholdPX || Math.abs(yDiff) >= thresholdPX;

    if (!isThresholdExceeded) {
      /* reset values */
      xDown = null;
      yDown = null;
      return;
    }
    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /* most significant */
      if (xDiff > 0) {
        /* left swipe */
        onSwipe("left");
      } else {
        /* right swipe */
        onSwipe("right");
      }
    } else if (yDiff > 0) {
      /* up swipe */
      onSwipe("up");
    } else {
      /* down swipe */
      onSwipe("down");
    }
    xDown = null;
    yDown = null;
  }

  return {
    onTouchStart: handleTouchStart,
    onTouchMove: handleTouchMove
  };
};
export default useSwipe;
