/**
 * @param {string} tag - The tags of the merchant.
 * @returns {string} - Returning merchant tag.
 */

export const getMerchantTagType = tagList => {
  const isOfficialPartner = tagList?.includes("OfficialPartner");
  const isAuthorizedDealer = tagList?.includes("AuthorizedDealer");

  const merchantTagList = {
    [isAuthorizedDealer && isOfficialPartner]: "both",
    [isAuthorizedDealer && !isOfficialPartner]: "authorizedDealer",
    [!isAuthorizedDealer && isOfficialPartner]: "officialPartner"
  };
  return merchantTagList[true];
};

/**
 * @param {string} tag - The tag of the merchant.
 * @returns {Object} - Returning tag data {title:string,description:string}.
 */

export const getMerchantTagData = tag => {
  const merchantTagList = {
    both: {
      title: "Resmi Satıcı",
      description:
        "Resmi Satıcı, marka tescil belgesi sunan ve marka adı ile mağaza adı aynı olan satıcılara verilen bir etikettir.",
      itemName: "Resmi satıcı",
      tagName: "Both"
    },
    authorizedDealer: {
      title: "Yetkili Satıcı",
      description:
        "Yetkili Satıcı, resmi satıcı tarafından yetkilendirilmiş, markanın ürünlerini satabilme belgesine sahip satıcılara verilen bir etikettir",
      itemName: "Yetkili satıcı",
      tagName: "AuthorizedDealer"
    },
    officialPartner: {
      title: "Resmi Satıcı",
      description:
        "Resmi Satıcı, marka tescil belgesi sunan ve marka adı ile mağaza adı aynı olan satıcılara verilen bir etikettir.",
      itemName: "Resmi satıcı",
      tagName: "OfficialPartner"
    }
  };
  return merchantTagList[tag];
};

/**
 * @param {string} tagList - The tags of the merchant.
 * @param {string} tag - The tag name of the merchant.
 * @returns {boolean}.
 */

export const isThereTag = (tagList, tag) => {
  return tagList?.includes(tag);
};
