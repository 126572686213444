import React, { useState } from "react";
import styles from "./ImageCarousel.scss";
import ImageCarouselItem from "./CarouselItem/ImageCarouselItem";
import ThumbnailWrapper from "./ThumbnailWrapper/ThumbnailWrapper";

const ImageCarousel = ({ videos, images, isMobile = false }) => {
  const [activeDataIndex, setActiveDataIndex] = useState(0);
  const allMedia = [
    ...(images && images?.length > 0 ? [...images] : []),
    ...(videos && videos.length > 0 ? [...videos] : [])
  ];
  return (
    <div className={styles.wrapper}>
      <ImageCarouselItem
        selectedItem={allMedia[activeDataIndex]}
        setActiveDataIndex={setActiveDataIndex}
        itemLength={allMedia.length}
        isMobile={isMobile}
      />
      <ThumbnailWrapper
        activeDataIndex={activeDataIndex}
        isMobile={isMobile}
        setActiveDataIndex={setActiveDataIndex}
        allMedia={allMedia}
      />
    </div>
  );
};

export default ImageCarousel;
