import React from "react";
import styles from "./OneStarRating.scss";
import { IconStar } from "../../../assets/components";
import cx from "classnames";
import { numberFormatter } from "Utils/helpers/number";

const OneStarRating = ({ rate, totalRate, showTotalRateText = false, classname }) => (
  <div
    className={cx(styles.wrapper, {
      [classname]: classname
    })}
  >
    <IconStar fill='#F69F29' width={9} height={9} />
    <span>{rate}</span>
    {totalRate === null || totalRate === undefined ? null : <span>({numberFormatter(totalRate, 1)})</span>}
    {showTotalRateText && <span> değerlendirme</span>}
  </div>
);

export default OneStarRating;
