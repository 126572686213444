import React, { useRef } from "react";
import styles from "./VisualCommentRow.scss";
import VisualCommentCard from "./VisualCommentCard/VisualCommentCard";
import useCheckMobileScreen from "Utils/hooks/useCheckMobileScreen";
import { MAX_SHOW_LENGTH_COMMENT, MIN_SHOW_LENGTH_COMMENT } from "../contants";
import Carousel from "@cosmos/carousel";
import ModalManager from "../../../FollowButton/ModalManager/ModalManager";
import useOnScreen from "../../../../../utils/hooks/useOnScreen";
import DaVinciService from "../../../../../services/davinciService";
import { DAVINCI_PAGE_MERCHANT } from "Utils/constants";

const VisualCommentRow = ({ merchantDetail, row, index }) => {
  const { isMobile } = useCheckMobileScreen();
  const baseModalRef = useRef(null);
  const observerRef = useRef();
  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);

  const onCardClick = (comment, commentIndex) => {
    baseModalRef.current.toggleModalVisibility(true, "commentDetail", { ...comment, index: commentIndex });
    davinci.clickVisualCommentRowItemEvent(row, index + 1, comment, commentIndex + 1);
  };
  const gotoProduct = (comment, commentIndex) => {
    davinci.clickVisualCommentModalGoToProductButtonEvent(row, index + 1, comment, commentIndex + 1);
  };
  const getSortingTypeText = () => {
    if (row.sortingType === "star") {
      return "Görselli yorumlar, yüksek puanlı yorumlar kriterine göre sıralanarak gösterilmektedir.";
    }
    return "Görselli yorumlar, faydalı içeriğe sahip olma kriterine göre sıralanarak gösterilmektedir.";
  };
  useOnScreen(observerRef, () => davinci.viewVisualCommentRowEvent(row, index + 1));
  return (
    <div ref={observerRef} key={index}>
      <section className={styles.header}>
        <h2 className={styles.title}>Görselli yorumlar</h2>
        <div className={styles.sortingType}>*{getSortingTypeText()}</div>
      </section>
      {!isMobile && row.data.length === MIN_SHOW_LENGTH_COMMENT && (
        <div className={styles.commentRow} style={{ maxWidth: 1200 }}>
          {row.data.map((comment, index) => (
            <VisualCommentCard key={comment.id} comment={comment} onClick={() => onCardClick(comment, index)} />
          ))}
        </div>
      )}
      {!isMobile && row.data.length > MIN_SHOW_LENGTH_COMMENT && row.data.length <= MAX_SHOW_LENGTH_COMMENT && (
        <div className={styles.carouselRoot} style={{ maxWidth: 1200 }}>
          <Carousel>
            {row.data.map((comment, index) => (
              <div className={styles.carouselItem} key={comment.id}>
                <VisualCommentCard comment={comment} onClick={() => onCardClick(comment, index)} />
              </div>
            ))}
          </Carousel>
        </div>
      )}
      {isMobile && row.data.length > MIN_SHOW_LENGTH_COMMENT && row.data.length <= MAX_SHOW_LENGTH_COMMENT && (
        <div className={styles.carouselRoot}>
          <Carousel>
            {row.data.map((comment, index) => (
              <div className={styles.carouselItem} key={comment.id}>
                <VisualCommentCard comment={comment} onClick={() => onCardClick(comment, index)} />
              </div>
            ))}
          </Carousel>
        </div>
      )}
      <ModalManager
        ref={baseModalRef}
        merchantDetail={merchantDetail}
        clickEvents={{
          gotoProduct
        }}
        classNames={{ portalClassName: styles.portalClassName }}
        device={isMobile ? "mobile" : "desktop"}
      />
    </div>
  );
};

export default VisualCommentRow;
