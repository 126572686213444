import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={48} height={48} fill='none' {...props}>
    <rect width={45.5} height={45.5} x={1.25} y={1.25} fill='#000' fillOpacity={0.6} rx={22.75} />
    <path
      fill='#fff'
      stroke='#fff'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={3.429}
      d='M19.714 15.429 33.678 24l-13.964 8.571V15.43Z'
    />
    <rect width={45.5} height={45.5} x={1.25} y={1.25} stroke='#fff' strokeWidth={2.5} rx={22.75} />
  </svg>
);
export default SvgComponent;
