import React, { createContext, useContext, useState } from "react";

const RowContext = createContext();

export default RowContext;

export const RowContextProvider = ({ children }) => {
  const [followingStatus, setFollowingStatus] = useState(null);
  const [dailyCouponLimit, setDailyCouponLimit] = useState(null); // IMPORTANT to set initial value for dailyCouponLimit
  const [isFollowBtnLoading, setIsFollowBtnLoading] = useState(true);
  const [canBeFollowing, setCanBeFollowing] = useState(true);

  const values = {
    followingStatus,
    setFollowingStatus,
    dailyCouponLimit,
    setDailyCouponLimit,
    isFollowBtnLoading,
    setIsFollowBtnLoading,
    setCanBeFollowing,
    canBeFollowing,
  };

  return <RowContext.Provider value={values}>{children}</RowContext.Provider>;
};

export const useRowContext = () => useContext(RowContext);
