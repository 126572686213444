import React, { memo } from "react";
import { getPositionPercent } from "./utils/getPositionPercent";
import { getTimeScale } from "./utils/getTimeScale";
import cx from "classnames";
import styles from "./Slider.scss";
import useCheckMobileScreen from "../../../../../../utils/hooks/useCheckMobileScreen";
export const TimeCodeItem = memo(
  ({
    label = "",
    startTime,
    maxTime,
    endTime,
    trackWidth = 0,
    currentTime,
    seekHoverTime,
    bufferTime,
    isTimePassed = false,
    isBufferPassed = false,
    isHoverPassed = false,
    onHover = () => undefined,
    withGap,
  }) => {
    const { isMobile } = useCheckMobileScreen();
    const positionPercent = getPositionPercent(maxTime, startTime);
    const translateX = (trackWidth / 100) * positionPercent;
    const timeDiff = endTime - startTime;
    const widthPercent = (timeDiff / maxTime) * 100;
    const width = (trackWidth / 100) * widthPercent;
    const mainClassName = `${styles.main} ${withGap && styles.withGap}`;
    const mainMobileClassName = `${styles.mainMobile} ${withGap && styles.withGap}`;

    const currentTimeScale = getTimeScale(currentTime, startTime, endTime, isTimePassed);

    const seekHoverTimeScale = getTimeScale(seekHoverTime, startTime, endTime, isHoverPassed);

    const bufferTimeScale = getTimeScale(bufferTime, startTime, endTime, isBufferPassed);

    const handleMouseMove = () => onHover(label);
    return (
      <div
        className={cx({
          [mainMobileClassName]: isMobile,
          [mainClassName]: !isMobile,
        })}
        onMouseMove={handleMouseMove}
        style={{
          width: `${width}px`,
          left: `${translateX}px`,
        }}
      >
        <div
          className={[styles.innerSeekBlock, styles.buffered].join(" ")}
          data-test-id='test-buffered'
          style={{ transform: `scaleX(${bufferTimeScale})` }}
        />

        <div
          className={[styles.innerSeekBlock, styles.seekHover].join(" ")}
          data-test-id='test-seek-hover'
          style={{ transform: `scaleX(${seekHoverTimeScale})` }}
        />

        <div
          className={[styles.innerSeekBlock, styles.connect].join(" ")}
          style={{ transform: `scaleX(${currentTimeScale})` }}
        />
      </div>
    );
  }
);
