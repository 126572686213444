import * as React from "react";

const SvgStarIcon = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={12} height={12} viewBox='0 0 12 12' {...props}>
    <g fillRule='evenodd'>
      <path d='M11.777 4.364c-.109-.293-.389-.489-.702-.49H8.03c-.106 0-.2-.066-.236-.167L6.702.611C6.592.318 6.313.124 6 .124c-.313 0-.593.194-.702.487l-.003.008-1.09 3.088c-.035.1-.13.167-.236.167H.925c-.316 0-.598.198-.705.494-.108.297-.019.63.224.832l2.591 2.15c.08.066.11.174.078.272l-1.089 3.265c-.103.31.006.65.27.843.264.193.622.192.885-.001l2.673-1.96c.088-.064.208-.064.295 0l2.672 1.96c.264.193.622.194.886.002.265-.193.374-.534.27-.844L8.887 7.619c-.033-.097-.002-.205.077-.271l2.597-2.153c.24-.204.326-.536.216-.83z' />
    </g>
  </svg>
);

export default SvgStarIcon;
