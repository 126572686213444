import * as React from "react";
const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={120} height={120} fill='none' {...props}>
    <g>
      <rect width={40} height={40} x={40} y={30} fill='#fff' fillOpacity={0.9} rx={20} />
      <path fill='#FF6000' d='m70 50-15 8.66V41.34L70 50Z' />
      <rect width={39.375} height={39.375} x={40.313} y={30.313} stroke='#E5E5E5' strokeWidth={0.625} rx={19.688} />
    </g>
    <defs>
      <filter id='a' width={120} height={120} x={0} y={0} colorInterpolationFilters='sRGB' filterUnits='userSpaceOnUse'>
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={6} />
        <feGaussianBlur stdDeviation={8} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_266_90738' />
        <feColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0' />
        <feBlend in2='effect1_dropShadow_266_90738' result='effect2_dropShadow_266_90738' />
        <feColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={10} />
        <feGaussianBlur stdDeviation={20} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
        <feBlend in2='effect2_dropShadow_266_90738' result='effect3_dropShadow_266_90738' />
        <feBlend in='SourceGraphic' in2='effect3_dropShadow_266_90738' result='shape' />
      </filter>
    </defs>
  </svg>
);
export default SvgComponent;
