import React from "react";
import styles from "./VideoPlayer.scss";

const VideoPlayer = ({ link, classNames, autoPlay, disabled, preload }) => (
  <div className={`${styles.wrapper} ${classNames?.wrapper ?? ""}`}>
    <video autoPlay={autoPlay} controls={!disabled} className={styles.videoPlayer} preload={preload}>
      <source src={link} />
    </video>
  </div>
);

VideoPlayer.defaultProps = {
  disabled: false,
  autoPlay: false,
  preload: "metadata"
};
export default VideoPlayer;
