import React, { useEffect, useRef, useState } from "react";
import { getHoverTimePosition } from "./utils/getHoverTimePosition";
import { timeToTimeString } from "./utils/timeToTimeString";
import { TimeCodeItem } from "./TimeCodeItem";
import { isInRange } from "./utils/isInRange";
import { positionToMs } from "./utils/positionToMs";
import { getEndTimeByIndex } from "./utils/getEndTimeByIndex";
import { TimeCodes } from "./TimeCodes";
import styles from "./Slider.scss";

export const Slider = ({
  max = 1000,
  currentTime = 0,
  bufferTime = 0,
  hideThumbTooltip = false,
  offset = 0,
  secondsPrefix = "",
  minutesPrefix = "",
  onChange = (time, offsetTime) => undefined,
  limitTimeTooltipBySides = true,
  timeCodes,
  getPreviewScreenUrl,
}) => {
  const [seekHoverPosition, setSeekHoverTime] = useState(0);
  const [trackWidth, setTrackWidth] = useState(0);

  const [label, setLabel] = useState("");
  const seeking = useRef(false);
  const mobileSeeking = useRef(false);
  const trackElement = useRef(null);
  const hoverTimeElement = useRef(null);

  const isThumbActive = seekHoverPosition > 0 || seeking.current;
  const thumbClassName = isThumbActive
    ? [styles.thumb, styles.active].join(" ")
    : [styles.thumb, styles.active].join(" ");
  const hoverTimeClassName = isThumbActive ? [styles.hoverTime, styles.active].join(" ") : styles.hoverTime;

  const hoverTimeValue = positionToMs(max, seekHoverPosition, trackWidth);

  const hoverTimeString = timeToTimeString(max, hoverTimeValue, offset, minutesPrefix, secondsPrefix);
  const hoverTimePosition = getHoverTimePosition(
    seekHoverPosition,
    hoverTimeElement?.current,
    trackWidth,
    limitTimeTooltipBySides
  );

  const changeCurrentTimePosition = (pageX) => {
    const left = trackElement.current?.getBoundingClientRect().left || 0;
    let position = pageX - left;

    position = position < 0 ? 0 : position;
    position = position > trackWidth ? trackWidth : position;

    setSeekHoverTime(position);

    const percent = (position * 100) / trackWidth;
    const time = +(percent * (max / 100)).toFixed(0);

    onChange(time, time + offset);
  };

  const handleTouchSeeking = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!mobileSeeking.current) {
      return;
    }

    const { changedTouches } = event;

    let pageX = changedTouches?.[changedTouches.length - 1]?.pageX || 0;

    pageX = pageX < 0 ? 0 : pageX;

    changeCurrentTimePosition(pageX);
  };

  const handleSeeking = (event) => {
    if (seeking.current) {
      changeCurrentTimePosition(event.pageX);
    }
  };

  const setTrackWidthState = () => {
    if (trackElement.current) {
      setTrackWidth(trackElement.current.offsetWidth);
    }
  };

  const handleTrackHover = (clear, event) => {
    const left = trackElement.current?.getBoundingClientRect().left || 0;
    const position = clear ? 0 : event.pageX - left;

    setSeekHoverTime(position);
  };

  const getThumbHandlerPosition = () => {
    const position = trackWidth / (max / currentTime);
    return { transform: `translateX(${position}px)` };
  };

  const setMobileSeeking = (state = true) => {
    mobileSeeking.current = state;
    setSeekHoverTime(state ? seekHoverPosition : 0);
  };

  const setSeeking = (state, event) => {
    event.preventDefault();

    handleSeeking(event);
    seeking.current = state;

    setSeekHoverTime(state ? seekHoverPosition : 0);
  };

  const mouseSeekingHandler = (event) => {
    setSeeking(false, event);
  };

  const mobileTouchSeekingHandler = () => {
    setMobileSeeking(false);
  };

  useEffect(() => {
    if (!mobileSeeking.current) {
      return;
    }

    const currentCode = timeCodes?.find(({ fromMs }, index) => {
      const endTime = getEndTimeByIndex(timeCodes, index, max);

      return isInRange(currentTime, fromMs, endTime);
    });

    if (currentCode?.description !== label) {
      setLabel(currentCode?.description || "");
    }
  }, [currentTime, label, max, timeCodes]);

  useEffect(() => {
    setTrackWidthState();

    window.addEventListener("resize", setTrackWidthState);
    window.addEventListener("mousemove", handleSeeking);
    window.addEventListener("mouseup", mouseSeekingHandler);
    window.addEventListener("touchmove", handleTouchSeeking);
    window.addEventListener("touchend", mobileTouchSeekingHandler);

    return () => {
      window.removeEventListener("resize", setTrackWidthState);
      window.removeEventListener("mousemove", handleSeeking);
      window.removeEventListener("mouseup", mouseSeekingHandler);
      window.removeEventListener("touchmove", handleTouchSeeking);
      window.removeEventListener("touchend", mobileTouchSeekingHandler);
    };
  }, [max, offset, trackWidth]);

  return (
    <div className={styles.slider}>
      <div
        className={isThumbActive ? styles.track : styles.track}
        ref={trackElement}
        onMouseMove={(event) => handleTrackHover(false, event)}
        onMouseLeave={(event) => handleTrackHover(true, event)}
        onMouseDown={(event) => setSeeking(true, event)}
        onTouchStart={() => setMobileSeeking(true)}
        data-testid='main-track'
      >
        {!timeCodes && (
          <TimeCodeItem
            trackWidth={trackWidth}
            maxTime={max}
            startTime={0}
            endTime={max}
            currentTime={currentTime}
            bufferTime={bufferTime}
            seekHoverTime={hoverTimeValue}
          />
        )}
      </div>
      {!hideThumbTooltip && (
        <div className={hoverTimeClassName} style={hoverTimePosition} ref={hoverTimeElement} data-testid='hover-time'>
          {label && <div>{label}</div>}
          {hoverTimeString}
        </div>
      )}

      <div className={thumbClassName} data-testid='testThumb' style={getThumbHandlerPosition()}>
        <div className={styles.handler} />
      </div>
    </div>
  );
};
