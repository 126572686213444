import { useEffect, useMemo, useState } from "react";
import pick from "lodash/pick";
import { isBrowser } from "Utils/helpers/browser";

const ADDRESS_KEYS = [
  "cities",
  "cityCode",
  "cityName",
  "code",
  "name",
  "receiverCity",
  "receiverDistrict",
  "receiverTown",
  "receiverTownCode",
  "addressName"
];
const getAddressParams = data => {
  const { cityCode, code, ...rest } = pick(data, ADDRESS_KEYS);

  return {
    ...(code && { cities: code }),
    ...(cityCode && { cities: cityCode }),
    ...rest
  };
};
const useLocationCookie = ({
  cityName = "",
  cityCode = "",
  receiverCity = "",
  receiverTown = "",
  receiverTownCode = "",
  receiverDistrict = "",
  addressName = ""
} = {}) => {
  const [address, setAddress] = useState({
    name: cityName,
    code: cityCode,
    receiverCity,
    receiverTown,
    receiverTownCode,
    receiverDistrict,
    addressName
  });

  useEffect(() => {
    const locationChangeCallback = data => {
      const { city = {}, district = {}, town = {}, address = {} } = data;

      setAddress({
        ...city,
        receiverCity: city?.name,
        receiverDistrict: district?.name,
        receiverTown: town?.name,
        receiverTownCode: town?.code,
        addressName: address?.addressName
      });
    };

    isBrowser() &&
      window &&
      window.HbEventBus?.on("shipping.voltran.shippingLocation.location.change", locationChangeCallback);
  }, []);

  const addressParams = useMemo(() => getAddressParams(address), [address]);
  return addressParams;
};

export { useLocationCookie, getAddressParams };
