import React from "react";
import styles from "./CommentDetailModal.scss";
import IconClose from "../../../../../../assets/icons/icon-circle-close.svg";
import ImageCarousel from "../../../../ImageCarousel/ImageCarousel";
import CommentOwner from "./CommentOwner/CommentOwner";
import CommentSection from "./CommentSection/CommentSection";
import CommentProduct from "./CommentProduct/CommentProduct";

const CommentDetailModal = ({ comment, onCloseModal, clickEvents }) => (
  <div className={styles.wrapper}>
    <header className={styles.header}>
      <img src={IconClose} alt='icon-close' onClick={onCloseModal} className={styles.closeIcon} />
      <h2>Fotoğraflı değerlendirmeler</h2>
    </header>
    <div className={styles.body}>
      <section className={styles.imageSection}>
        <ImageCarousel videos={comment.video} images={comment.media} />
      </section>
      <section className={styles.infoSection}>
        <div className={styles.commentSection}>
          <CommentOwner comment={comment} />
          <CommentSection comment={comment} />
        </div>
        <CommentProduct comment={comment} clickEvents={clickEvents} />
      </section>
    </div>
  </div>
);

export default CommentDetailModal;
