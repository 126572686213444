import { isBrowser } from "../utils/helpers/browser";

const IS_FOLLOWING = "MerchantContent.IsFollowing";
const FOLLOW = "MerchantContent.Follow";
const COM_FOLLOW = "MerchantContent.ComFollow";
const COUPON_WIN_FOLLOW = "Campaign.MerchantCoupons.isMerchantFollowed";
const TAB_CHANGE = "MerchantContent.TabChange";

const HBEventBusService = () => {
  if (isBrowser() && window && window.HbEventBus) {
    const { HbEventBus } = window;
    const listen = (subject, listenFunc) => HbEventBus.on(subject, listenFunc);
    const off = (subject, listenFunc) => HbEventBus.off(subject, listenFunc);
    const emit = (subject, payload) => HbEventBus.emit(subject, payload);

    return {
      listen,
      emit,
      off,
      onIsFollowing: (listenFunc) => listen(IS_FOLLOWING, listenFunc),
      offIsFollowing: () => off(IS_FOLLOWING),
      emitIsFollowing: (merchantId, isFollowing) => emit(IS_FOLLOWING, { merchantId, isFollowing }),
      emitFollow: (merchantId, visibility) => emit(FOLLOW, { merchantId, visibility }),
      emitComFollow: (merchantId, visibility) => emit(COM_FOLLOW, { merchantId, visibility }),
      onCouponWinFollow: (listenFunc) => listen(COUPON_WIN_FOLLOW, listenFunc),
      onTabChange: (tabName) => emit(TAB_CHANGE, { tabName }),
      onTabChangeListen: listenFunc => listen(TAB_CHANGE, listenFunc)
    };
  }
};

export default HBEventBusService;
