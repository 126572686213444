import { getTokenServer } from "../utils/helpers/cookie";
import registerApiClientInstance from "./apiClientSideInstance";
import { merchantExternalURL, merchantInternalURL } from "./urls";

let apiClientInstances = {};

// ATTENTION!!!
// baseUrl parameters is only for client-side fetching.
// You can define the baseURL of server-side fetching in the component file.

const apiManager = (baseURL, client, cookies) => {
  // Client Side Fetching Operations
  // by using our own ApiManager
  if (!client && !cookies) {
    // Register api instance for client-side only.
    if (!apiClientInstances[baseURL]) {
      apiClientInstances[baseURL] = registerApiClientInstance(baseURL);
    }
    const apiInstance = apiClientInstances[baseURL];
    return {
      GET: (path, config) => apiInstance.get(path, config),
      POST: (path, body) => apiInstance.post(path, body),
      DELETE: (path, body) => apiInstance.delete(path, body),
      PUT: (path, body) => apiInstance.put(path, body)
    };
  }

  // Server Side Fetching Operations
  // by using VoltranApiManager
  const token = getTokenServer(cookies);
  const defaultHeaders = isMerchantContentApi(baseURL)
    ? {
        Authorization: "Bearer " + token
      }
    : {
        Authorization: "Bearer " + token,
        "X-Team-Key": "MContent"
      };

  return {
    GET: (path, headers) => client.get(path, {}, headers || defaultHeaders).execute(),
    POST: (path, body, headers) => client.post(path, body, headers || defaultHeaders).execute(),
    DELETE: (path, body, headers) => client.delete(path, body, headers || defaultHeaders).execute(),
    PUT: (path, body, headers) => client.put(path, body, headers || defaultHeaders).execute()
  };
};

export const isMerchantContentApi = baseURL => {
  return baseURL === merchantInternalURL || baseURL === merchantExternalURL;
};

export default apiManager;
