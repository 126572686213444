import React from "react";
import styles from "./GlassyCard.scss";
import cx from "classnames";

const GlassyCard = ({ children, classname }) => (
  <div
    className={cx(styles.wrapper, {
      [classname]: classname
    })}
  >
    {children}
  </div>
);

export default GlassyCard;
