import React, { useRef, useState } from "react";
import ModalManager from "../../VideoRow/ModalManager/ModalManager";
import DynamicGridLayoutItem from "./DynamicGridLayoutItem/DynamicGridLayoutItem";

const DynamicGridLayout = ({
  layoutData,
  isMobile,
  maxWidth = 1200,
  gap = 24,
  row,
  merchantDetail,
  rowIndex,
  splitLayoutEvents
}) => {
  const { data, splitLayout } = layoutData;
  const videoModalRef = useRef(null);
  const layout = isMobile ? splitLayout.mobileLayout : splitLayout.desktopLayout;
  const gridAreas = layout.map(row => `"${row.join(" ")}"`).join(" ");
  const gridTemplateColumns = layout[0].map(area => `${splitLayout.size[area].maxWidth}fr`).join(" ");
  const uniqueAreas = Array.from(new Set(layout.flat()));
  const columns = Array.from(new Set(layout[0]));
  const [renderedData, setRenderedData] = useState({});
  const gridStyle = {
    display: "grid",
    gridTemplateAreas: gridAreas,
    gridTemplateColumns,
    gap,
    maxWidth
  };

  const handleShowModal = async (item, bannerIndex, array) => {
    async function handleRenderedData() {
      setRenderedData({
        item,
        bannerIndex,
        array
      });
    }

    await handleRenderedData();
    videoModalRef.current?.toggleModalVisibility(true, "videoBanner");
  };

  const renderGridItem = (area, bannerIndex, array) => {
    const itemIndex = uniqueAreas.indexOf(area);
    if (itemIndex >= 0 && itemIndex < data.length) {
      const item = data[itemIndex];
      const aspectRatio = splitLayout.size[area].ratio || "1/1";
      return (
        <DynamicGridLayoutItem
          bannerItem={item}
          aspectRatio={aspectRatio}
          isMobile={isMobile}
          handleShowModal={() => handleShowModal(item, bannerIndex, array)}
          splitLayout={splitLayout}
          area={area}
          maxWidth={maxWidth}
          gap={gap}
          splitLayoutEvents={splitLayoutEvents(bannerIndex)}
          columnsLength={columns.length}
        />
      );
    }
    return null;
  };

  return (
    <div style={gridStyle}>
      {uniqueAreas.map((area, areaIndex, array) => renderGridItem(area, areaIndex, array))}
      <ModalManager
        ref={videoModalRef}
        id={renderedData?.item?.id + renderedData?.item?.rowIndex}
        key={renderedData?.item?.id + renderedData?.item?.rowIndex}
        device={isMobile ? "mobile" : "desktop"}
        source={renderedData?.item?.videoUrl}
        poster={renderedData?.item?.imageUrl}
        title={renderedData?.item?.title}
        url={renderedData?.item?.url}
        rowLength={renderedData?.array?.length}
        products={renderedData?.item?.products}
        rowIndex={rowIndex}
        bannerIndex={renderedData?.bannerIndex}
        merchantDetail={merchantDetail}
        row={row}
        onTagClickEvent={prodIndex => splitLayoutEvents(renderedData?.bannerIndex).onVideoTagClick(prodIndex)}
        onUrlClick={() => splitLayoutEvents(renderedData?.bannerIndex).onUrlClick()}
      />
    </div>
  );
};

export default DynamicGridLayout;
