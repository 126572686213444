export const getAddToCartWTData = ({ product }) => {
  const contentGroup = {
    1: "Storefront",
    2: "_SpeedCart",
    3: "Category",
    4: window?.location?.href || ""
  };
  const customEcommerceParameter = {
    16: `CategoryPage ${window?.utagData?.category_name_hierarchy}` || "",
    17: "Listing",
    18: product?.listing?.merchantName,
    19: product?.listing?.merchantId
  };

  return {
    contentGroup,
    product: product?.sku,
    productCost: product?.listing?.priceInfo?.price,
    productQuantity: 1,
    productStatus: "add",
    customEcommerceParameter
  };
};
