import React from "react";
import styles from "./Thumbnail.scss";
import cx from "classnames";
import IconVideoPlay from "../../../../assets/icons/icon-video-play.svg";
import IconVideoPlayHover from "../../../../assets/icons/icon-video-play-hover.svg";
import { getImageUrl } from "../../RowComponents/CommentRow/utils";

const Thumbnail = ({ media, isActive, onClick, isMobile = false }) => (
  <div
    className={cx(styles.wrapper, {
      [styles.active]: isActive
    })}
    onClick={onClick}
    onMouseEnter={onClick}
  >
    {media?.thumbnailUrl ? (
      <div className={styles.playIcon}>
        <img src={IconVideoPlay} alt='IconVideoPlay' />
        <img src={IconVideoPlayHover} alt='IconVideoPlayHover' />
      </div>
    ) : null}
    <img
      src={media?.thumbnailUrl || getImageUrl(media?.url, !isMobile ? 80 : 68)}
      className={styles.mediaImage}
      alt='thumbnail-img'
    />
  </div>
);

export default Thumbnail;
