import React, { useRef } from "react";
import styles from "./TemplateBannerRow.scss";

// hooks
import useOnScreen from "../../../../utils/hooks/useOnScreen";
import useCheckMobileScreen from "../../../../utils/hooks/useCheckMobileScreen";

// components
import BannerCarousel from "../BannerRow/BannerCarousel/BannerCarousel";
import Carousel from "@cosmos/carousel";

// services && constants
import DaVinciService from "../../../../services/davinciService";
import { DAVINCI_PAGE_MERCHANT } from "Utils/constants";
import {
  BannerComponentDataRowSubTypeEnums,
  MAX_SHOW_LENGTH_CUSTOM_BANNER,
  MIN_SHOW_LENGTH_CUSTOM_BANNER,
  MIN_SHOW_MOBIL_LENGTH_CUSTOM_BANNER
} from "../BannerRow/contants";

const TemplateBannerRow = ({ row, merchantDetail, index }) => {
  const { isMobile } = useCheckMobileScreen();
  const observerRef = useRef();

  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);
  const clickEventHandler = (bannerIndex, event) => {
    davinci[event](row, index + 1, bannerIndex);
  };
  const handleRenderBannerByRowSubType = () => {
    switch (row.subType) {
      case BannerComponentDataRowSubTypeEnums.TEMPLATE_BANNER:
        useOnScreen(observerRef, () => davinci.viewTemplateBannerRowEvent(row, index + 1));
        return (
          <div ref={observerRef} key={index}>
            {!isMobile &&
              row.data.length <= MAX_SHOW_LENGTH_CUSTOM_BANNER &&
              row.data.length >= MIN_SHOW_LENGTH_CUSTOM_BANNER && (
                <div className={styles.bannerRow} style={{ maxWidth: 1200 }}>
                  {row?.data.length > 0 &&
                    row?.data?.map((banner, bannerIndex) => (
                      <div key={banner.id} style={{ width: "100%" }}>
                        <BannerCarousel
                          banner={banner}
                          hasAdditionalInfo={false}
                          size={row?.data.length}
                          clickEventHandler={() => clickEventHandler(bannerIndex, "clickTemplateBannerRowEvent")}
                        />
                      </div>
                    ))}
                </div>
              )}
            {!isMobile && row.data.length > MAX_SHOW_LENGTH_CUSTOM_BANNER && (
              <div className={styles.carouselRoot} style={{ maxWidth: 1200 }}>
                <Carousel>
                  {row?.data.length > 0 &&
                    row?.data?.map((banner, bannerIndex) => (
                      <div key={banner.id} className={styles.carouselContainer}>
                        <BannerCarousel
                          banner={banner}
                          hasAdditionalInfo={false}
                          size={row?.data.length}
                          clickEventHandler={() => clickEventHandler(bannerIndex, "clickTemplateBannerRowEvent")}
                        />
                      </div>
                    ))}
                </Carousel>
              </div>
            )}
            {isMobile && row.data.length > MIN_SHOW_MOBIL_LENGTH_CUSTOM_BANNER && (
              <div className={styles.carouselRoot}>
                <Carousel>
                  {row?.data.length > 0 &&
                    row?.data?.map((banner, bannerIndex) => (
                      <div key={banner.id} className={styles.carouselContainer}>
                        <BannerCarousel
                          banner={banner}
                          hasAdditionalInfo={false}
                          size={row?.data.length}
                          clickEventHandler={() => clickEventHandler(bannerIndex, "clickTemplateBannerRowEvent")}
                        />
                      </div>
                    ))}
                </Carousel>
              </div>
            )}
            {isMobile && row.data.length === MIN_SHOW_MOBIL_LENGTH_CUSTOM_BANNER && (
              <div className={styles.bannerRow}>
                {row?.data.length > 0 &&
                  row?.data?.map((banner, bannerIndex) => (
                    <div key={banner.id} style={{ width: "100%" }}>
                      <BannerCarousel
                        banner={banner}
                        hasAdditionalInfo={false}
                        size={row?.data.length}
                        clickEventHandler={() => clickEventHandler(bannerIndex, "clickTemplateBannerRowEvent")}
                      />
                    </div>
                  ))}
              </div>
            )}
            {!isMobile && row.data.length === MIN_SHOW_MOBIL_LENGTH_CUSTOM_BANNER && (
              <div className={styles.bannerRow} style={{ maxWidth: 1200 }}>
                {row?.data.length > 0 &&
                  row?.data?.map((banner, bannerIndex) => (
                    <div key={banner.id} style={{ width: "100%" }}>
                      <BannerCarousel
                        banner={banner}
                        hasAdditionalInfo={false}
                        size={row?.data.length}
                        clickEventHandler={() => clickEventHandler(bannerIndex, "clickTemplateBannerRowEvent")}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
        );
      default:
        return <></>;
    }
  };

  return handleRenderBannerByRowSubType();
};

export default TemplateBannerRow;
