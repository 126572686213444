import apiManager from "./apiManager";
import { merchantExternalURL, merchantInternalURL } from "./urls";
import { findExperimentVariationIdWithBucketPayload, getLayoutHeader } from "../utils/helpers/cookie";
import { AB_TESTING_PARAMS } from "../utils/constants";

export const getLayoutByMerchantId = (merchantId, apiClient, cookies) => {
  return apiManager(merchantExternalURL, apiClient, cookies).GET(`/layouts/id/${merchantId}`);
};

export const getPublishedLayoutByMerchantIdInternal = (
  merchantId,
  onlyBackground,
  apiClient,
  cookies,
  showOtherProducts,
  ABTestingParams,
  isBot
) => {
  const variationIdWithBucketPayload = findExperimentVariationIdWithBucketPayload(
    AB_TESTING_PARAMS.PERSONALIZED_READY_PRODUCTS.EXPERIMENT_KEY,
    ABTestingParams?.experimentCookie
  );
  const variationId = variationIdWithBucketPayload?.[0];
  let headers;
  if (variationId) {
    const anonId = ABTestingParams?.anonId;
    const userId = ABTestingParams?.userId;
    headers = getLayoutHeader(variationId, userId, anonId);
  }

  return apiManager(merchantInternalURL, apiClient, cookies).GET(
    `/layoutsV2/id/${merchantId}?extraCalls=true${onlyBackground ? "&onlyBackground=true" : ""}${
      showOtherProducts ? "&showOtherProducts=true" : ""
    }${
      isBot ? "&isBot=true" : ""
    }`,
    headers && { headers }
  );
};
