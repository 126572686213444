import React, { useState, useImperativeHandle, useCallback } from "react";
import cx from "classnames";
import Modal from "@cosmos/hmodal";
// Desktop modals
// Mobile modals
// Styles
import styles from "./ModalManager.scss";
import BannerVideoModalMobile from "./Mobile/BannerVideoModalMobile/loadable";
import BannerVideoModalDesktop from "./Desktop/BannerVideoModalDesktop/loadable";

const ModalManager = React.forwardRef(
  (
    {
      source,
      url,
      poster,
      device,
      title,
      rowLength,
      products,
      bannerIndex,
      row,
      rowIndex,
      merchantDetail,
      onTagClickEvent,
      onUrlClick
    },
    ref
  ) => {
    const [visibility, setVisibility] = useState({ isVisible: false, modalType: "" });
    const toggleModalVisibility = (isVisible, modalType, callback) => {
      setVisibility(() => {
        callback?.();
        return { isVisible, modalType };
      });
    };

    useImperativeHandle(ref, () => ({
      toggleModalVisibility
    }));

    const onCloseModal = useCallback(() => {
      toggleModalVisibility(false, "");
    }, [visibility]);

    const modalContent = modalType => {
      switch (device) {
        case "desktop":
          switch (modalType) {
            case "videoBanner":
              return (
                <BannerVideoModalDesktop
                  source={source}
                  poster={poster}
                  url={url}
                  onCloseModal={onCloseModal}
                  title={title}
                  showModal
                  rowLength={rowLength}
                  products={products}
                  bannerIndex={bannerIndex}
                  row={row}
                  rowIndex={rowIndex}
                  merchantDetail={merchantDetail}
                  onTagClickEvent={onTagClickEvent}
                  onUrlClick={onUrlClick}
                />
              );
          }
          break;
        case "mobile":
          switch (modalType) {
            case "videoBanner":
              return (
                <BannerVideoModalMobile
                  source={source}
                  poster={poster}
                  url={url}
                  onCloseModal={onCloseModal}
                  title={title}
                  products={products}
                  bannerIndex={bannerIndex}
                  row={row}
                  rowIndex={rowIndex}
                  merchantDetail={merchantDetail}
                  onTagClickEvent={onTagClickEvent}
                  onUrlClick={onUrlClick}
                />
              );
          }
      }
    };

    return (
      <Modal
        className={cx({
          [styles.ModalMobile]: device === "mobile",
          [styles.ModalDesktop]: device === "desktop"
        })}
        viewType='DIALOG'
        isOpen={visibility.isVisible}
        openingAnimation
        shouldCloseOnOverlayClick
        scrollLock
        onClose={onCloseModal}
        closeButtonEnabled={false}
        headerClassName={cx({
          [styles.HeaderMobile]: device === "mobile",
          [styles.HeaderDesktop]: device === "desktop"
        })}
      >
        {modalContent(visibility.modalType)}
      </Modal>
    );
  }
);

export default ModalManager;
