import React from "react";
import { PlayIcon } from "../../../../../../assets/components";
import styles from "../DynamicGridLayout.scss";
import TaggedProductPopover from "../../../BannerRow/TaggedProductPopover/loadable";

const DynamicGridLayoutItem = ({
  bannerItem,
  isMobile,
  splitLayout,
  area,
  aspectRatio,
  maxWidth,
  gap,
  splitLayoutEvents,
  handleShowModal,
  columnsLength
}) => {
  const findTaggedProduct = tag => bannerItem.products?.find(product => product.variantList[0].sku === tag.sku);
  const findTaggedProductIndex = tag =>
    bannerItem.products?.findIndex(product => product.variantList[0].sku === tag.sku);
  const bannerHasTaggedProduct =
    Object.hasOwn(bannerItem, "type") && bannerItem.type === "product" && bannerItem.tags?.length > 0;
  const { onTagView, onTagClick, onVideoClick, onUrlClick } = splitLayoutEvents;
  const renderTags = () =>
    bannerItem.tags.map(tag => {
      const taggedProduct = findTaggedProduct(tag);
      return (
        taggedProduct && (
          <TaggedProductPopover
            tag={tag}
            isMobile={isMobile}
            product={taggedProduct}
            prodIndex={findTaggedProductIndex(tag)}
            onView={onTagView}
            onClick={onTagClick}
          />
        )
      );
    });
  const urlToString = () => {
    const ctrlRegex = /https?:\/\/(?:www.)?/g;
    if (bannerItem.url?.match(ctrlRegex) !== null) {
      return bannerItem.url;
    } else {
      if (bannerItem.url?.includes("www.")) {
        return `https://${bannerItem.url}`;
      } else {
        return `https://www.${bannerItem.url}`;
      }
    }
  };

  return (
    <a
      key={bannerItem?.id}
      href={!bannerHasTaggedProduct && bannerItem.resourceType === "image" ? urlToString() : null}
      target='_blank'
      onClick={!bannerHasTaggedProduct && bannerItem.resourceType === "image" ? () => onUrlClick?.() : null}
      style={{
        maxWidth: !isMobile
          ? `${Math.floor(
              (Number(maxWidth) - (columnsLength - 1) * gap) /
                ((1200 - (columnsLength - 1) * gap) / Number(splitLayout.size[area].maxWidth))
            )}px`
          : null,
        gridArea: area,
        aspectRatio,
        backgroundImage: `url(${bannerItem?.imageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "12px",
        position: "relative",
        height: "100%",
        width: "100%"
      }}
    >
      {bannerHasTaggedProduct && renderTags()}
      {bannerItem?.resourceType === "video" ? (
        <PlayIcon
          role='button'
          className={styles.PlayIcon}
          onClick={async () => {
            await handleShowModal();
            onVideoClick();
          }}
        />
      ) : null}
    </a>
  );
};
export default DynamicGridLayoutItem;
