import { ALLOWED_SEARCH_PARAMS, FIRSAT_CADIRI_MERCHANT_ID } from "../constants";
import { QUERY_TO_TABS, queryTabNameEnums } from "../../universal/partials/MerchantTabular/constants";

export const isBrowser = () => {
  return process.env.BROWSER;
};

export const getSearchParam = param => {
  if (!isBrowser()) return null;
  const params = new URL(document.location).searchParams;
  return params.get(param);
};

export const checkSearchParamExistsExceptAllowed = queryParams => {
  const filteredArray = Object.keys(queryParams).filter(key => ALLOWED_SEARCH_PARAMS.includes(key));
  return filteredArray.length > 0;
};

export const checkSearchParamExistsOnUrl = () =>
  ALLOWED_SEARCH_PARAMS.some(searchParams => getSearchParam(searchParams) !== null);
export const checkSearchParamExistsOnUrlOnServer = query =>
  Object.entries(query).filter(entry => ALLOWED_SEARCH_PARAMS.includes(entry[0])).length > 0;

export const getActiveTab = (query, showLayoutTab, merchantId = "", isSuperTabUser) => {
  const isFirsatCadiriEnabled = merchantId === FIRSAT_CADIRI_MERCHANT_ID;
  if (!query.tab && checkSearchParamExistsOnUrlOnServer(query)) {
    return QUERY_TO_TABS[queryTabNameEnums.ALL_PRODUCTS];
  }
  if (isFirsatCadiriEnabled && query.tab === queryTabNameEnums.PROFILE) {
    return QUERY_TO_TABS[queryTabNameEnums.STORE];
  }
  if (!isSuperTabUser && query.tab === queryTabNameEnums.SUPER_TAB) {
    return QUERY_TO_TABS[queryTabNameEnums.STORE];
  }

  return QUERY_TO_TABS[
    query.tab || (showLayoutTab && showLayoutTab === "false" ? queryTabNameEnums.ALL_PRODUCTS : queryTabNameEnums.STORE)
  ];
};
export const removeQueryParam = (paramKey, queryParams) => {
  queryParams.delete(paramKey);
  const query = queryParams?.toString()?.length > 0 ? `?${queryParams.toString()}` : "";
  window.history.replaceState("", "", `${window.location.pathname}${query}`);
};
export const updateQueryParams = (newParams, queryParams) => {
  Object.keys(newParams).forEach(key => {
    queryParams.set(key, newParams[key]);
  });
  window.history.replaceState("", "", `${window.location.pathname}?${queryParams.toString()}`);
};
export const getLocalStorageItem = item => {
  return JSON.parse(localStorage.getItem(item));
};

export const setLocalStorageItem = (item, value) => {
  localStorage.setItem(item, JSON.stringify(value));
};
