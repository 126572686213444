import { AB_TESTING_PARAMS } from "../constants";

export const getCookie = (cname) => {
  if (typeof window === "undefined") return;
  let name = cname + "=";
  let decodedCookie;
  decodedCookie = decodeURIComponent(document?.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const getTokenClient = () => getCookie("jwt");

export const isUserLoggedIn = () => {
  return Boolean(getCookie("jwt"));
};

export const getTokenServer = (cookies) => {
  if (cookies) {
    cookies = JSON.parse(cookies);
    return cookies.jwt;
  }
  return null;
};

export const findExperimentVariationIdWithBucketPayload = (experimentId, hex) => {
  if (!hex) return;
  let string = "";
  if (hex) {
    for (let i = 0; i < hex.length; i += 2) {
      string += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    }
  }
  let variationIdWithBucketPayload = [];
  if (string) {
    const cookieSplit = string
      .split("#")
      .map((i) => i.split("|"))
      .filter((i) => i.find((el) => el === experimentId));
    if (cookieSplit.length && cookieSplit[0].length > 2) {
      variationIdWithBucketPayload = [cookieSplit[0][1], cookieSplit[0][2]];
    }
  }

  return variationIdWithBucketPayload;
};

export const getLayoutHeader = (VariationId, userId, anonId) => {
  const header =
    anonId || userId
      ? {
          [AB_TESTING_PARAMS.PERSONALIZED_READY_PRODUCTS.BUCKETS.PersonalizedList]: {
            ...(anonId && { anonId }),
            ...(userId && { userId }),
          },
          [AB_TESTING_PARAMS.PERSONALIZED_READY_PRODUCTS.BUCKETS.Control]: {},
        }
      : undefined;
  return header?.[VariationId];
};
