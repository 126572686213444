import React from "react";
import ProductCard from "@cosmos/product-card";
import styles from "./ProductItem.scss";
import { getColorsByProduct } from "Utils/helpers/utils";
import { getAddToCartWTData } from "../../../../../services/webtrekkUtils";
import { triggerWebtrekkEvent } from "../../../../../services/webtrekkService";

const ProductItem = props => {
  const { index, productCardType, item, hbAdult, address, townToTownEAD, isBot } = props;
  const sendAddToCartWebtrekkEvent = status => {
    if (status === "success") {
      const wtData = getAddToCartWTData({ product: item?.variantList.find(variant => variant.isDefault) });
      triggerWebtrekkEvent(wtData);
    }
  };

  return (
    <ProductCard
      prefix='merchantContent'
      key={index}
      {...item}
      isLinkBlank
      showImageHeader
      showCampaignText
      position={index + 1}
      cityCode={address?.cities}
      receiverTownCode={address?.receiverTownCode}
      productCardType={productCardType}
      townToTownEAD={townToTownEAD}
      productInfoClassName={styles.ProductInfo}
      showImageReviewIcon
      showRightBar
      showButton
      colors={getColorsByProduct(item)}
      hbAdult={hbAdult}
      addToCartCallBack={sendAddToCartWebtrekkEvent}
      isGoogleBotLinkingEnabled={isBot} 
    />
  );
};

export default ProductItem;
