import React from "react";
import { CommentComponentSubTypeEnums } from "./contants";
import VisualCommentRow from "./VisualCommentRow/VisualCommentRow";
import TextCommentRow from "./TextCommentRow/TextCommentRow";

const CommentRow = ({ merchantDetail, row, index }) => {
  switch (row.subType) {
    case CommentComponentSubTypeEnums.visualComment:
      return <VisualCommentRow key={row.id} row={row} index={index} merchantDetail={merchantDetail} />;
    case CommentComponentSubTypeEnums.textComment:
      return <TextCommentRow key={row.id} row={row} index={index} merchantDetail={merchantDetail} />;
    default:
      return <></>;
  }
};

export default CommentRow;
