import { throwError } from "../../../../utils/errorHandler/errorHandler";
import { getLocalStorageItem, setLocalStorageItem } from "../../../../utils/helpers/browser";
import { isSameDay } from "../../../../utils/helpers/date";

export const checkMerchantIdExists = (merchantId, context) => {
  if (!merchantId) throwError("merchantId girilmelidir.", context);
};

export const checkMerchantNameExists = (merchantName, context) => {
  if (!merchantName) throwError("merchantName girilmelidir.", context);
};

export const checkUserIdExists = (userId, context) => {
  if (!userId) throwError("userId girilmelidir.", context);
};

export const checkMerchantIsActiveOrNot = (data) => {
  if (!data.merchantDetail) throwError("Satıcı objesi bulunamadı.");

  const { isActive, isSuspended } = data.merchantDetail;
  if (!isActive || isSuspended) {
    throwError(
      `Satıcı aktif değildir. ID: ${data.merchantDetail.id} isActive: ${isActive}, isSuspended: ${isSuspended} `
    );
  }
};

export const getDisplayMerchantName = (merchantDetail, h1title) => {
  return h1title
    ? h1title
    : merchantDetail?.merchantOptions.brandName
    ? merchantDetail.merchantOptions.brandName
    : merchantDetail.legalName;
};

export const storeVisitedMerchants = (merchantId) => {
  const key = "VISITED_MERCHANTS";
  const storedFields = getLocalStorageItem(key);

  if (!storedFields) {
    setLocalStorageItem(key, {
      [merchantId]: {
        counter: 0,
        date: new Date(),
      },
    });
  } else {
    setLocalStorageItem(key, {
      ...storedFields,
      [merchantId]: {
        counter: isSameDay(storedFields[merchantId]?.date) ? storedFields[merchantId]?.counter + 1 : 0,
        date: new Date(),
      },
    });
  }
};
