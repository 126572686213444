import * as React from "react";
const PlayIconSvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 144 144' width={144} height={144} fill='none' {...props}>
    <g>
      <rect width={64} height={64} x={40} y={30} fill='#fff' fillOpacity={0.9} rx={32} />
      <path fill='#FF6000' d='M88 62 64 75.856V48.144L88 62Z' />
      <rect width={63} height={63} x={40.5} y={30.5} stroke='#E5E5E5' rx={31.5} />
    </g>
    <defs>
      <filter id='a' width={144} height={144} x={0} y={0} colorInterpolationFilters='sRGB' filterUnits='userSpaceOnUse'>
        <feFlood floodOpacity={0} result='BackgroundImageFix' />
        <feColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={6} />
        <feGaussianBlur stdDeviation={8} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0' />
        <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_80_83790' />
        <feColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={2} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0' />
        <feBlend in2='effect1_dropShadow_80_83790' result='effect2_dropShadow_80_83790' />
        <feColorMatrix in='SourceAlpha' result='hardAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
        <feOffset dy={10} />
        <feGaussianBlur stdDeviation={20} />
        <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0' />
        <feBlend in2='effect2_dropShadow_80_83790' result='effect3_dropShadow_80_83790' />
        <feBlend in='SourceGraphic' in2='effect3_dropShadow_80_83790' result='shape' />
      </filter>
    </defs>
  </svg>
);
export default React.memo(PlayIconSvg);
