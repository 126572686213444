import React from "react";

function store() {
  return (
    <div>
      <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M44.4167 28.1667V44.4167C44.4167 45.135 44.1313 45.8238 43.6234 46.3317C43.1155 46.8397 42.4266 47.125 41.7083 47.125H11.9167C11.1984 47.125 10.5095 46.8397 10.0016 46.3317C9.49367 45.8238 9.20833 45.135 9.20833 44.4167V28.1667M9.20833 36.2917H32.2292M32.2292 47.125V28.1667M43.3297 6.5H10.2953C9.98707 6.49995 9.68803 6.6049 9.44745 6.79756C9.20688 6.99022 9.03911 7.25909 8.97181 7.55986L6.5 18.6875C6.5 19.7649 6.92801 20.7983 7.68988 21.5601C8.45175 22.322 9.48506 22.75 10.5625 22.75C11.6399 22.75 12.6733 22.322 13.4351 21.5601C14.197 20.7983 14.625 19.7649 14.625 18.6875C14.625 19.7649 15.053 20.7983 15.8149 21.5601C16.5767 22.322 17.6101 22.75 18.6875 22.75C19.7649 22.75 20.7983 22.322 21.5601 21.5601C22.322 20.7983 22.75 19.7649 22.75 18.6875C22.75 19.7649 23.178 20.7983 23.9399 21.5601C24.7017 22.322 25.7351 22.75 26.8125 22.75C27.8899 22.75 28.9233 22.322 29.6851 21.5601C30.447 20.7983 30.875 19.7649 30.875 18.6875C30.875 19.7649 31.303 20.7983 32.0649 21.5601C32.8267 22.322 33.8601 22.75 34.9375 22.75C36.0149 22.75 37.0483 22.322 37.8101 21.5601C38.572 20.7983 39 19.7649 39 18.6875C39 19.7649 39.428 20.7983 40.1899 21.5601C40.9517 22.322 41.9851 22.75 43.0625 22.75C44.1399 22.75 45.1733 22.322 45.9351 21.5601C46.697 20.7983 47.125 19.7649 47.125 18.6875L44.6514 7.55986C44.5848 7.25908 44.4174 6.99006 44.1771 6.79733C43.9367 6.6046 43.6378 6.4997 43.3297 6.5Z'
          stroke='#DADADA'
          stroke-width='3.71429'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
      </svg>
    </div>
  );
}

export default store;
