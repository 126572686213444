import appConfig from "../appConfig";

const webtrekkApiClient = {};

const setWebtrekkConfig = () => {
  const config = {
    trackId: appConfig?.webtrekkId,
    trackDomain: "hepsiburada01.webtrekk.net",
    domain: "www.hepsiburada.com",
    linkTrack: "standard",
    cookie: "1",
    mediaCode: "wt_mc",
    contentId: "",
    forceHTTPS: true
  };

  window.webtrekkConfig = config;

  if (window?.webtrekkV3) {
    // eslint-disable-next-line new-cap
    webtrekkApiClient.webtrekkInstance = new window.webtrekkV3(config);
  } else {
    window.addEventListener("WEBTREKK_LOAD_SUCCESS", () => {
      if (window?.webtrekkV3) {
        // eslint-disable-next-line new-cap
        webtrekkApiClient.webtrekkInstance = new window.webtrekkV3(config);
      }
    });
  }
};

const triggerWebtrekkEvent = data => {
  webtrekkApiClient?.webtrekkInstance?.sendinfo(data);
};

export { setWebtrekkConfig, triggerWebtrekkEvent };
