import React from "react";
import styles from "./CommentSection.scss";
import OneStarRating from "../../../../../OneStarRating/OneStarRating";
import {onListNameClick, onSellerNameClick} from "../../../../../RowComponents/CommentRow/utils";
import cx from "classnames";

const CommentSection = ({comment}) => (
  <div className={styles.commentWrapper}>
    <div className={styles.comment}>{comment.review.content}</div>
    <div className={styles.divider}/>
    {comment.featureStars?.length > 0 ? (
      <div className={styles.evaluatedFeatures}>
        <h3>Değerlendirilen özellikler</h3>
        <ul className={styles.feature}>
          {comment.featureStars.map(feature => (
            <li key={feature.featureId}>
              <img src={feature.iconUrl} alt='iconUrl'/>
              <span>{feature.text}</span>
              <OneStarRating rate={feature.star}/>
            </li>
          ))}
        </ul>
      </div>
    ) : null}
    <div className={styles.purchasedSeller}>
      Kullanıcı bu ürünü{" "}
      <span
        className={cx(styles.seller, {
          [styles.notClickable]: comment.order.merchantUrl === "hepsiburada"
        })}
        onClick={() => onSellerNameClick(comment.order.merchantUrl)}
      >
        {comment.order.merchantName}
      </span>{" "}
      satısından
      {comment.order.purchasedListInformation ? <span>,{" "}
          <span
            className={cx(styles.seller)}
            onClick={() => onListNameClick(comment.order.purchasedListInformation.listUrl)}
          >
            {comment.order.purchasedListInformation.listOwnerName}
          </span> adlı kullanıcının listesinden
      </span>
      : null} aldı.
    </div>
  </div>
);

export default CommentSection;
