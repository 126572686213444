import React, { useRef } from "react";
import styles from "./SplitBannerRow.scss";
import useCheckMobileScreen from "Utils/hooks/useCheckMobileScreen";
import DaVinciService from "../../../../services/davinciService";
import { DAVINCI_PAGE_MERCHANT } from "Utils/constants";
import useOnScreen from "Utils/hooks/useOnScreen";
import DynamicGridLayout from "./DynamicGridLayout/DynamicGridLayout";

const SplitBannerRow = ({ row, index, merchantDetail }) => {
  const { isMobile } = useCheckMobileScreen();
  const observerRef = useRef();

  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);

  useOnScreen(observerRef, () => davinci.viewSplitBannerRowEvent(row, index + 1));
  const splitLayoutEvents = bannerIndex => ({
    onTagView: prodIndex => davinci.viewSplitBannerProductTagEvent(row, index + 1, bannerIndex, prodIndex),
    onTagClick: prodIndex => davinci.clickSplitBannerProductTagEvent(row, index + 1, bannerIndex, prodIndex),
    onVideoClick: () => davinci.clickSplitBannerVideoEvent(row, index + 1, bannerIndex),
    onVideoTagClick: prodIndex => davinci.clickSplitBannerVideoTagEvent(row, index + 1, bannerIndex, prodIndex),
    onUrlClick: () => davinci.clickSplitBannerUrlEvent(row, index + 1, bannerIndex)
  });
  return (
    <div ref={observerRef} key={index}>
      <div className={styles.bannerRow} style={{ maxWidth: 1200 }}>
        <DynamicGridLayout
          layoutData={row}
          isMobile={isMobile}
          row={row}
          merchantDetail={merchantDetail}
          rowIndex={index}
          splitLayoutEvents={splitLayoutEvents}
        />
      </div>
    </div>
  );
};

export default SplitBannerRow;
