import * as React from "react";
const SvgComponent = props => (
  <svg xmlns='http://www.w3.org/2000/svg' width={48} height={48} fill='none' {...props} viewBox='0 0 48 48'>
    <g stroke='#fff' strokeLinecap='round' strokeLinejoin='round'>
      <path
        fill='#000'
        fillOpacity={0.6}
        strokeWidth={2.5}
        d='M24 46.286c12.308 0 22.286-9.978 22.286-22.286 0-12.308-9.978-22.286-22.286-22.286C11.692 1.714 1.714 11.692 1.714 24c0 12.308 9.978 22.286 22.286 22.286Z'
      />
      <path fill='#fff' strokeWidth={2.571} d='M19 15v18-18ZM29 15v18-18Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 0h48v48H0z' />
      </clipPath>
    </defs>
  </svg>
);
export default SvgComponent;
