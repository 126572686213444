import * as React from "react";
const PauseIconSvg = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={64} height={64} fill='none' {...props}>
    <rect width={62} height={62} x={1} y={1} fill='#000' fillOpacity={0.3} rx={31} />
    <g fill='#fff' clipPath='url(#a)'>
      <path d='M27.45 19.555h-6.098a1.84 1.84 0 0 0-1.84 1.84v21.21a1.84 1.84 0 0 0 1.84 1.84h6.098a1.84 1.84 0 0 0 1.84-1.84v-21.21a1.84 1.84 0 0 0-1.84-1.84ZM42.56 19.555h-6.097a1.84 1.84 0 0 0-1.84 1.84v21.21a1.84 1.84 0 0 0 1.84 1.84h6.098a1.84 1.84 0 0 0 1.84-1.84v-21.21a1.84 1.84 0 0 0-1.84-1.84Z' />
    </g>
    <rect width={62} height={62} x={1} y={1} stroke='#E5E5E5' strokeWidth={2} rx={31} />
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M16 16h32v32H16z' />
      </clipPath>
    </defs>
  </svg>
);
export default PauseIconSvg;
