const buildVersion = process.env.APP_BUILD_VERSION || "1";

const port = 3000;

module.exports = {
  port,
  voltranEnv: "prod",
  baseUrl: `https://merchantcontent-external.hepsiburada.com`,
  sfBaseUrl: "https://www.hepsiburada.com",
  sfLoginUrl: "https://www.hepsiburada.com/uyelik/giris",
  mediaUrl: `https://images.hepsiburada.net/merchantcontent/assets/prod/${buildVersion}`,
  logLevel: "warn",
  isCssClassNameObfuscationEnabled: true,
  services: {
    merchantContentApi: {
      client: "https://merchantcontent-api-external.hepsiburada.com",
      serverUrl: "http://merchantcontent-api-internal.hepsiburada.com"
    },
    campaign: {
      client: "https://obiwan-gw.hepsiburada.com",
      serverUrl: "http://severus.hepsiburada.com"
    },
    product: {
      client: "http://mordor-api.hepsiburada.com",
      serverUrl: "http://mordor-api.hepsiburada.com"
    },
    coupon: {
      client: "https://campaign-ui.hepsiburada.com",
      serverUrl: "https://campaign-ui.hepsiburada.com"
    },
    moria: {
      client: "http://moria-api.hepsiburada.com",
      serverUrl: "http://moria-api.hepsiburada.com"
    },
    storefrontMain: {
      client: "https://www.hepsiburada.com/api/v1",
      serverUrl: "http://sfmobileapi-internal.hepsiburada.com/api/v1"
    },
    hermesVoltran: {
      client: "https://usercontent.hepsiburada.com",
      serverUrl: "https://usercontent.hepsiburada.com"
    }
  },
  timeouts: {
    clientApiManager: 50 * 1000,
    serverApiManager: 50 * 1000
  },
  newrelicEnabled: true,
  hbusEnabled: true,
  voltranCommonUrl: "https://voltrancommon.hepsiburada.com/common.js",
  webtrekkId: "692379182816325"
};
