import styles from "./BannerCarousel.scss";
import captionStyles from "./common/ImageCaption.scss";

import { MAX_SHOW_LENGTH_CUSTOM_BANNER, MIN_SHOW_LENGTH_CUSTOM_BANNER } from "../contants";

const ImageTitleLocationEnums = {
  CENTER: "center",
  LEFT: "alignLeft",
  RIGHT: "alignRight",
};

const ImageSizeRation = {
  VERTICAL: "vertical",
  HORIZONTAL: "horizontal",
  SQUARE: "square",
};

const prefix = "CarouselImage";

const handleSafeArea = (imageTitleLocation) => {
  switch (imageTitleLocation) {
    case ImageTitleLocationEnums.CENTER:
      return [styles[prefix], styles[`${prefix}_Center`]].join(" ");
    case ImageTitleLocationEnums.LEFT:
      return [styles[prefix], styles[`${prefix}_Right`]].join(" ");
    case ImageTitleLocationEnums.RIGHT:
      return [styles[prefix], styles[`${prefix}_Left`]].join(" ");
  }
};

const handleSafeAreWidth = (imageSizeRatio, size, isMobile) => {
  switch (imageSizeRatio) {
    case ImageSizeRation.HORIZONTAL:
      if (!isMobile && size >= MAX_SHOW_LENGTH_CUSTOM_BANNER) return styles.MaxWidth;
      if (isMobile && size >= MIN_SHOW_LENGTH_CUSTOM_BANNER) return styles.MaxWidth;
      return styles.MinWidth;
    case ImageSizeRation.VERTICAL:
      if (!isMobile && size >= MAX_SHOW_LENGTH_CUSTOM_BANNER)
        return [styles.MinWidth, styles.VerticalMinHeight].join(" ");
      if (!isMobile && size <= MIN_SHOW_LENGTH_CUSTOM_BANNER) return styles.VerticalMaxHeight;
      if (isMobile && size >= MIN_SHOW_LENGTH_CUSTOM_BANNER)
        return [styles.MaxWidth, styles.VerticalMinHeight].join(" ");
      return styles.MinWidth;
    case ImageSizeRation.SQUARE:
      if (!isMobile && size >= MAX_SHOW_LENGTH_CUSTOM_BANNER) return styles.MaxWidth;
      if (isMobile && size >= MIN_SHOW_LENGTH_CUSTOM_BANNER) return styles.MaxWidth;
      return styles.MinWidth;
  }
};

const handleCaptionPosition = (imageTitleLocation) => {
  const prefix = "Caption";
  switch (imageTitleLocation) {
    case ImageTitleLocationEnums.CENTER:
      return [captionStyles[prefix], captionStyles[`${prefix}_Center`]].join(" ");
    case ImageTitleLocationEnums.LEFT:
      return [captionStyles[prefix], captionStyles[`${prefix}_Left`]].join(" ");
    case ImageTitleLocationEnums.RIGHT:
      return [captionStyles[prefix], captionStyles[`${prefix}_Right`]].join(" ");
  }
};
export { ImageTitleLocationEnums, handleSafeArea, handleCaptionPosition, handleSafeAreWidth };
