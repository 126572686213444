/** dependencies */
import React, { useState } from "react";
import Button from "@cosmos/button";

/** components */
import styles from "./MerchantTagModalMobile.scss";
import { IconSolidCheckBlue, IconQuestion } from "../../../../../../assets/components";

/** services */
import { addFollowerToMerchant, removeFollowerFromMerchant } from "../../../../../../services/followerService";

/** context */
import { useRowContext } from "../../../../../../contexts/RowContext";

/** constants && utils */
import { FOLLOWING_TEXT, FOLLOW_AND_WIN_TEXT, FOLLOW_TEXT } from "./constants";
import { DAVINCI_PAGE_MERCHANT } from "../../../../../../utils/constants";
import { getMerchantTagData, getMerchantTagType } from "../../../../../../utils/helpers/merchantTag";

/** davinci */
import DaVinciService from "../../../../../../services/davinciService";
import appConfig from "../../../../../../appConfig";

const types = {
  AuthorizedDealer: {
    title: "Yetkili Satıcı",
    description:
      "Yetkili Satıcı, resmi satıcı tarafından yetkilendirilmiş, markanın ürünlerini satabilme belgesine sahip satıcılara verilen bir etikettir",
    itemName: "Yetkili satıcı"
  },
  OfficialPartner: {
    title: "Resmi Satıcı",
    description:
      "Resmi Satıcı, marka tescil belgesi sunan ve marka adı ile mağaza adı aynı olan satıcılara verilen bir etikettir.",
    itemName: "Resmi satıcı"
  },
  Both: {
    title: "Resmi Satıcı",
    description: "marka tescil belgesi sunan ve marka adı ile mağaza adı aynı olan satıcılara verilen bir etikettir.",
    itemName: "Resmi satıcı"
  }
};
const MerchantTagModalMobile = ({ onCloseModal, merchantDetail, toggleModalVisibility }) => {
  const { id } = merchantDetail;
  const { followingStatus, setFollowingStatus, dailyCouponLimit } = useRowContext();
  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);

  const [disabled, setDisabled] = useState(followingStatus?.isFollowing || false);
  const [followLoading, setFollowLoading] = useState(false);

  const merchantTagData = getMerchantTagData(getMerchantTagType(merchantDetail?.tagList));

  const isSuitableToWinCoupon = () => merchantDetail.coupon && followingStatus?.isFirstTime;

  const handleChangeFollowStatus = async isClickedOnRow => {
    if (disabled) return;
    try {
      setFollowLoading(true);
      if (followingStatus?.isFollowing) {
        const response = await removeFollowerFromMerchant(id);
        if (response.status) {
          setFollowingStatus(value => ({ ...value, isFollowing: false }));
          davinci.unfollowEvent(merchantDetail.coupon);
        }
      } else {
        const response = await addFollowerToMerchant(id);
        if (response.status) {
          showSuccessModalOnFollow(isClickedOnRow);
          setFollowingStatus(value => ({ ...value, isFollowing: true, isFirstTime: false }));
          setDisabled(true);
        }
      }
    } catch (error) {
      // console.log("Error:", error);
      if (error.toString()?.includes("401"))
        window.location.href = `${appConfig.sfLoginUrl}?ReturnUrl=${appConfig.sfBaseUrl}${window?.location?.pathname}`;
    } finally {
      setFollowLoading(false);
    }
  };
  const showSuccessModalOnFollow = async isClickedOnRow => {
    if (!followingStatus?.isFirstTime) {
      davinci.followAndWinEvent(merchantDetail.coupon, false, true);
      return;
    }

    let isCollected = false;

    toggleModalVisibility(true, "couponSuccess");

    if (isSuitableToWinCoupon() && (dailyCouponLimit > 0 || (dailyCouponLimit === 0 && !isClickedOnRow))) {
      try {
        toggleModalVisibility(false, "");
        const { couponId } = merchantDetail.coupon;
        const result = await collectMerchantCoupon(couponId);
        if (result.data.success) {
          toggleModalVisibility(true, "couponSuccess");
          isCollected = true;
        }
      } catch (error) {
        //console.log("Error:", error);
        /**
         * TODO: why setCouponFailReason undefined and use???
         */
        // const { code, title, message } = error.response.data;
        // setCouponFailReason({ code, title, message });
        toggleModalVisibility(true, "couponFailure");
      } finally {
        davinci.followAndWinEvent(merchantDetail.coupon, isCollected, isClickedOnRow);
      }
    } else {
      toggleModalVisibility(true, "startFollowing");
      davinci.followAndWinEvent(merchantDetail.coupon, false, true);
    }
  };

  const findButtonText = () => {
    if (followingStatus?.isFollowing) {
      return FOLLOWING_TEXT;
    } else {
      if ((dailyCouponLimit === null && merchantDetail.coupon) || (isSuitableToWinCoupon() && dailyCouponLimit > 0)) {
        return FOLLOW_AND_WIN_TEXT;
      }
      return FOLLOW_TEXT;
    }
  };
  const hasAuthorizedDealer = merchantDetail?.partners?.some(partner => partner.partnerShipType === "AuthorizedDealer");
  const hasOfficialPartner = merchantDetail?.partners?.some(partner => partner.partnerShipType === "OfficialPartner");
  return (
    <div className={styles.root}>
      <section className={styles.modal}>
        {!!merchantDetail?.partners && (
          <ul className={styles.PopoverItems}>
            {merchantDetail?.partners?.map(partner => (
              <li className={styles.PopoverItem} key={partner.id}>
                <IconSolidCheckBlue className={styles.Icon} />
                <span>{partner.productBrandName}</span>
                <span>{types[partner.partnerShipType].title}</span>
              </li>
            ))}
          </ul>
        )}
        <div className={styles.PopoverBody}>
          {!!merchantDetail?.partners && (
            <>
              {hasAuthorizedDealer && (
                <p className={styles.InfoText}>
                  <IconQuestion className={styles.Icon} width={18} height={18} />
                  <strong>{types["AuthorizedDealer"]?.title}</strong>, marka tescil belgesi sunan ve marka adı ile
                  mağaza adı aynı olan satıcılara verilen bir etikettir.
                </p>
              )}
              {hasOfficialPartner && (
                <p className={styles.InfoText}>
                  <IconQuestion className={styles.Icon} width={18} height={18} />
                  <strong>{types["OfficialPartner"]?.title}</strong>, marka tescil belgesi sunan ve marka adı ile mağaza
                  adı aynı olan satıcılara verilen bir etikettir.
                </p>
              )}
            </>
          )}

          {!!merchantTagData?.title && !merchantDetail?.partners && (
            <p className={styles.InfoText}>
              <IconQuestion className={styles.Icon} />
              <strong>{types[(merchantTagData?.tagName)]?.title}</strong>, marka tescil belgesi sunan ve marka adı ile
              mağaza adı aynı olan satıcılara verilen bir etikettir.
            </p>
          )}
        </div>
        {/*
          <div className={styles.modalTitle}>
            <IconSolidCheck />
            <h1>
              {merchantTagData?.title} <span>nedir?</span>
            </h1>
          </div>

          <p className={styles.modalDescription}>{merchantTagData?.description}</p>
        */}

        <div className={styles.modalButtonGroup}>
          <a>
            <Button onClick={onCloseModal} size={"large"} kind={"ghost"} className={"detailButton"}>
              Kapat
            </Button>
          </a>
          <Button
            onClick={() => handleChangeFollowStatus(true)}
            loading={followLoading ? followLoading.toString() : null}
            kind={"primary"}
            className='modalButton'
            disabled={disabled}
          >
            {findButtonText()}
          </Button>
        </div>
      </section>
    </div>
  );
};

export default MerchantTagModalMobile;
