import React from "react";
import styles from "./ImageCaption.scss";
import { handleCaptionPosition } from "../constants";

const ImageCaption = ({ showHeadline, titleOrder, title, subTitle, imageTitleLocation }) => {
  if (!showHeadline) return null;
  return (
    <div className={styles.Container}>
      {titleOrder?.title === 1 ? (
        <>
          <figcaption className={`${handleCaptionPosition(imageTitleLocation)} ${styles.SubTitle}`}>
            {subTitle}
          </figcaption>
          <figcaption className={`${handleCaptionPosition(imageTitleLocation)} ${styles.Title}`}>{title}</figcaption>
        </>
      ) : (
        <>
          <figcaption className={`${handleCaptionPosition(imageTitleLocation)} ${styles.Title}`}>{title}</figcaption>
          <figcaption className={`${handleCaptionPosition(imageTitleLocation)} ${styles.SubTitle}`}>
            {subTitle}
          </figcaption>
        </>
      )}
    </div>
  );
};

export default ImageCaption;
