import React, { useRef } from "react";
import useGetElementHeight from "../../../../utils/hooks/useGetElementHeight";
import styles from "./CampaignRow.scss";
import { merchantStoreCouponExternalUrl } from "../../../../services/urls";
import DaVinciService from "../../../../services/davinciService";
import { DAVINCI_PAGE_MERCHANT } from "Utils/constants";
import useOnScreen from "Utils/hooks/useOnScreen";
import VFragmentForwardingRef from "../../VFragment/VFragmentForwardingRef";

const CampaignRow = ({ row, index, merchantDetail, backgroundType }) => {
  const campaignHeight = useGetElementHeight(`#merchantStoreCampaign-${backgroundType}`);
  const observerRef = useRef();
  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);
  useOnScreen(
    observerRef,
    () => {
      if (campaignHeight !== "0px") {
        davinci.viewCampaignRowEvent(row, index + 1);
      }
    },
    [campaignHeight]
  );
  return (
    <div
      className={campaignHeight === "0px" ? "" : styles.merchantStoreCampaign}
      id={`merchantStoreCampaign-${backgroundType}`}
    >
      <VFragmentForwardingRef
        url={`${merchantStoreCouponExternalUrl}/MerchantCampaigns?merchantId=${merchantDetail?.id}`}
        name='MerchantStoreCampaign'
        ref={observerRef}
        isAppendScripts
      />
    </div>
  );
};

export default CampaignRow;
