import React, { useRef } from "react";
import Carousel from "@cosmos/carousel";
import useOnScreen from "../../../../utils/hooks/useOnScreen";
import DaVinciService from "../../../../services/davinciService";

import BannerVideo from "./BannerVideo/BannerVideo";
import { VideoComponentDataRowSubTypeEnums } from "./constants";
import { DAVINCI_PAGE_MERCHANT } from "Utils/constants";
import useCheckMobileScreen from "../../../../utils/hooks/useCheckMobileScreen";

import styles from "./VideoRow.scss";

const VideoRow = ({ row, merchantDetail, index }) => {
  const { isMobile } = useCheckMobileScreen();
  const observerRef = useRef();
  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);
  const clickEventHandler = (bannerIndex, event) => {
    davinci[event](row, index + 1, bannerIndex);
  };

  useOnScreen(observerRef, () => davinci.viewVideoBannerRowEvent(row, index + 1));
  switch (row.subType) {
    case VideoComponentDataRowSubTypeEnums.DETAIL:
      return (
        <>
          {!isMobile && (
            <div className={styles.Container} ref={observerRef} key={index}>
              {row?.data?.map((banner, bannerIndex, array) => (
                <BannerVideo
                  rowIndex={index}
                  bannerIndex={bannerIndex}
                  merchantDetail={merchantDetail}
                  row={row}
                  key={banner?.id.toString()}
                  type='large'
                  source={banner?.videoUrl}
                  poster={banner?.imageUrl}
                  showModal={array.length > 1 || isMobile}
                  carousel={isMobile}
                  rowLength={array.length}
                  title={banner?.title}
                  url={banner?.url}
                  products={banner?.products}
                  clickEventHandler={() => clickEventHandler(index, "clickVideoBannerRowEvent")}
                />
              ))}
            </div>
          )}
          {isMobile && (
            <div className={styles.MobileContainer} ref={observerRef} key={index}>
              <Carousel>
                {row?.data?.map((banner, bannerIndex, array) => (
                  <BannerVideo
                    rowIndex={index}
                    bannerIndex={bannerIndex}
                    merchantDetail={merchantDetail}
                    row={row}
                    key={banner?.id}
                    type='large'
                    source={banner?.videoUrl}
                    poster={banner?.imageUrl}
                    showModal={array.length > 1 || isMobile}
                    carousel={isMobile}
                    rowLength={array.length}
                    title={banner?.title}
                    url={banner?.url}
                    products={banner?.products}
                    clickEventHandler={() => clickEventHandler(index, "clickVideoBannerRowEvent")}
                  />
                ))}
              </Carousel>
            </div>
          )}
        </>
      );
  }
};

export default VideoRow;
