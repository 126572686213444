import React, { useRef } from "react";
import Carousel from "@cosmos/carousel";
import ProductItem from "./ProductItem";
import DaVinciService from "../../../../services/davinciService";
import useOnScreen from "../../../../utils/hooks/useOnScreen";
import { DAVINCI_PAGE_MERCHANT, LAYOUT_COMPONENTS } from "Utils/constants";
import styles from "./ProductRow.scss";
import { useLocationCookie } from "Utils/hooks/useLocationCookie";

const ProductRow = ({ location, row, merchantDetail, isMobile = false, index, hbAdultValue, setTabOtherProducts, isBot }) => {
  const address = useLocationCookie(location?.query);
  const observerRef = useRef();
  useOnScreen(observerRef, () => davinci.viewProductRowEvent(row, index + 1));
  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);

  const seeAllHandler = () => {
    if (row.subType === "otherProducts") {
      setTabOtherProducts?.();
      if (typeof window !== "undefined") window.scrollTo({ top: 0, lef: 0, behavior: "smooth" });
      davinci.clickToSeeAllEvent(row, index + 1);
      return;
    }
    davinci.clickToSeeAllEvent(row, index + 1);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(LAYOUT_COMPONENTS[row.name].queryParam.key, LAYOUT_COMPONENTS[row.name].queryParam.value);
    window.location.search = searchParams.toString();
  };

  const seeAllEnabled = () => Boolean(row.type === "product" && row.subType !== "custom");

  const clickEventHandler = prodIndex => {
    davinci.clickProductRowEvent(row, index + 1, prodIndex);
  };

  return (
    <div ref={observerRef} className={styles.root}>
      <div className={styles.header}>
        <h3 className={styles.rowTitle}>{row.name}</h3>
        {seeAllEnabled() && (
          <button type='button' className={styles.seeAllBtn}>
            <span onClick={seeAllHandler}>{isMobile ? "Tümü" : "Tümünü gör"}</span>
            <span />
          </button>
        )}
      </div>
      <Carousel prefix='productCardCarousel'>
        {row.data.map((product, index) => (
          <div className={styles.gridProductList} onClick={() => clickEventHandler(index)}>
            <ProductItem
              productCardType='comfort'
              index={index}
              item={product}
              hbAdult={hbAdultValue}
              address={address}
              isBot={isBot}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ProductRow;
