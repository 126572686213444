import React from "react";
import cx from "classnames";
import Button from "@cosmos/button";
import styles from "./CouponFailModal.scss";

const CouponFailModal = ({ onCloseModal, device, couponFailReason, isComFollowModal = false, isPagePDP }) => {
  const { code, title, message } = couponFailReason;

  const getTitle = () => {
    if (!code || !title.length) return "Kupon alınamadı!";
    else return title;
  };

  const getDesc = () => {
    if (!code || !message.length || message === "Kupon limiti dolmuştur.")
      return " Mağazayı takip etmeye başladın ancak kuponlar tükendi.";
    else return message;
  };

  return (
    <div
      className={cx(styles.modal, {
        [styles.mobile]: device === "mobile",
      })}
      data-testid='couponFail'
    >
      <div className={styles.warningIcon}></div>
      <h1 className={styles.modalTitle}>{getTitle()}</h1>
      <p className={styles.desc}>{getDesc()}</p>

      <div className={styles.modalButtonGroup}>
        <Button
          onClick={onCloseModal}
          size={"large"}
          kind={isPagePDP ? "secondary" : "primary"}
          className='modalButton'
          as='a'
        >
          <span className={styles.btnPrimaryText}>{isComFollowModal ? "Tamam" : "Alışverişe devam et"}</span>
        </Button>
      </div>
    </div>
  );
};

export default CouponFailModal;
