import axios from "axios";
import appConfig from "../appConfig";
import { getTokenClient } from "../utils/helpers/cookie";
import { isMerchantContentApi } from "./apiManager";

const registerApiClientInstance = (baseURL) => {
  const apiClientInstance = axios.create({
    baseURL,
    timeout: appConfig.timeouts.clientApiManager,
  });

  // Client-side API Instance
  apiClientInstance.interceptors.request.use(
    (config) => {
      const token = getTokenClient();
      config.headers["Content-Type"] = "application/json";
      config.headers["Authorization"] = "Bearer " + token;
      if (isMerchantContentApi(baseURL)) {
        config.headers["X-Team-Key"] = "MContent-SF";
      }

      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  apiClientInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      // Ignore for daily coupon service.
      if (error.response?.status === 401 && isMerchantContentApi(error?.response?.config?.baseURL)) {
        window.location.href = `${appConfig.sfLoginUrl}?ReturnUrl=${appConfig.sfBaseUrl}${window?.location?.pathname}`;
        return Promise.reject(error);
      }
      return Promise.reject(error);
    }
  );

  return apiClientInstance;
};

export default registerApiClientInstance;
