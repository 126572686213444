import React from "react";
import Thumbnail from "../Thumbnail/Thumbnail";
import styles from "./ThumbnailWrapper.scss";
import classNames from "classnames";

const ThumbnailWrapper = ({ allMedia, activeDataIndex, setActiveDataIndex, isMobile }) => {
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.isOverFlowed]: allMedia.length > 5 && !isMobile
      })}
    >
      {allMedia.map((media, index) => (
        <Thumbnail
          media={media}
          isActive={index === activeDataIndex}
          isMobile={isMobile}
          onClick={() => setActiveDataIndex(index)}
        />
      ))}
    </div>
  );
};

export default ThumbnailWrapper;
