import * as React from "react";
const SvgComponent = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={16} height={16} fill='none' {...props}>
    <path
      fill='#2C96FF'
      fillRule='evenodd'
      d='m14.452 4.2-6.633 8.756a1.001 1.001 0 0 1-1.385.176L1.696 9.449a.926.926 0 0 1-.152-1.325.988.988 0 0 1 1.363-.147l3.95 3.072 6.035-7.962a.979.979 0 0 1 .897-.416.97.97 0 0 1 .802.573.921.921 0 0 1-.139.957l-.001-.001Z'
      clipRule='evenodd'
    />
  </svg>
);
export default SvgComponent;
