import { hbusEnabled } from "../appConfig";
import { isBrowser } from "../utils/helpers/browser";
import { voltranEnv } from "../appConfig";

const HBBusManager = () => {
  if (isBrowser() && hbusEnabled && !window.hepsiBus && voltranEnv !== "prod") {
    const script = document.createElement("script");
    script.src = "https://images.hepsiburada.net/hbus/hbus.min.v3.7.7.js";
    document.body.appendChild(script);
  }

  let queuedRequestContexts = [];

  function queueRequest(eventName, eventData) {
    queuedRequestContexts.push({
      eventName,
      eventData,
    });
  }

  function triggerQueuedEvents() {
    if (queuedRequestContexts) {
      queuedRequestContexts.forEach(({ eventName, eventData }) => {
        trackEvent(eventName, eventData);
      });
    }
  }

  function trackEvent(eventName, eventData) {
    window.hepsiBus.track(eventName, eventData);
  }

  if (process.env.BROWSER && window?.addEventListener) {
    window.addEventListener("hbus:ready", () => {
      if (window.hepsiBus) {
        triggerQueuedEvents();
      } else {
        queuedRequestContexts = null;
      }
    });
  }

  return {
    sendEventRequest: (eventName, eventData) => {
      return new Promise((resolve) => {
        if (window.hepsiBus) {
          trackEvent(eventName, eventData);
        } else {
          queueRequest(eventName, eventData);
        }

        resolve({ data: {} });
      });
    },
  };
};

export default HBBusManager;
