import React from "react";

const iconSolidCheck = (props) => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle cx='10' cy='10' r='10' fill='#5097E9' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.3765 6.83331L9.84873 14.13C9.71762 14.3 9.52096 14.4111 9.30429 14.4389C9.08651 14.4666 8.86651 14.4078 8.69429 14.2766L4.74762 11.2078C4.39873 10.9366 4.34318 10.4411 4.62095 10.1033C4.89984 9.76442 5.40873 9.70998 5.75651 9.98109L9.04762 12.5411L14.0776 5.90553C14.2421 5.66442 14.5298 5.5322 14.8254 5.55887C15.121 5.58665 15.3776 5.76998 15.4932 6.03665C15.6087 6.30331 15.5643 6.60887 15.3776 6.83442L15.3765 6.83331Z'
        fill='white'
      />
    </svg>
  );
};

export default iconSolidCheck;
