import React, { useState, useImperativeHandle, useCallback } from "react";
import Modal from "@cosmos/hmodal";
// Desktop modals
import StartedFollowModalDesktop from "./Desktop/StartedFollowModal/StartedFollowModal";
import CouponDealModalDesktop from "./Desktop/CouponDealModal/CouponDealModal";
import CouponFailModalDesktop from "./Desktop/CouponFailModal/CouponFailModal";
import CouponSuccessModalDesktop from "./Desktop/CouponSuccessModal/CouponSuccessModal";
import CommentDetailModalDesktop from "./Desktop/CommentDetailModal/CommentDetailModal";
// Mobile modals
import StartedFollowModalMobile from "./Mobile/StartedFollowModalMobile/StartedFollowModalMobile";
import CouponDealModalMobile from "./Mobile/CouponDealModalMobile/CouponDealModalMobile";
import CouponSuccessModalMobile from "./Mobile/CouponSuccessModalMobile/CouponSuccessModalMobile";
import RatingInfoModalMobile from "./Mobile/RatingInfoModalMobile/RatingInfoModalMobile";
import MerchantTagModalMobile from "./Mobile/MerchantTagModalMobile/MerchantTagModalMobile";
import CommentDetailModalMobile from "./Mobile/CommentDetailModalMobile/CommentDetailModalMobile";
// Styles
import styles from "./ModalManager.scss";

const ModalManager = React.forwardRef(
  (
    {
      merchantDetail,
      dailyCouponLimit,
      loading,
      addFollowFunc,
      device,
      davinci,
      couponFailReason,
      isComFollowModal = false,
      isPagePDP,
      classNames,
      clickEvents,
      isPageReviews
    },
    ref
  ) => {
    const [visibility, setVisibility] = useState({ isVisible: false, modalType: "", modalData: null });

    const toggleModalVisibility = (isVisible, modalType, modalData = null) =>
      setVisibility({ isVisible, modalType, modalData });

    useImperativeHandle(ref, () => ({
      toggleModalVisibility
    }));

    const onCloseModal = useCallback(() => {
      if (visibility.modalType === "coupon") {
        davinci.closeCouponEvent();
      }
      toggleModalVisibility(false, "");
    }, [visibility]);

    const modalContent = (modalType, modalData) => {
      switch (device) {
        case "desktop":
          switch (modalType) {
            case "startFollowing":
              return (
                <StartedFollowModalDesktop
                  merchantDetail={merchantDetail}
                  onCloseModal={onCloseModal}
                  isComFollowModal={isComFollowModal}
                  isPagePDP={isPagePDP}
                />
              );
            case "coupon":
              return (
                <CouponDealModalDesktop
                  onClick={addFollowFunc}
                  merchantDetail={merchantDetail}
                  customerDailyCouponsLimit={dailyCouponLimit}
                  loading={loading}
                />
              );
            case "couponSuccess":
              return (
                <CouponSuccessModalDesktop
                  onCloseModal={onCloseModal}
                  discountAmount={merchantDetail?.coupon?.discountAmount}
                  loading={loading}
                  isComFollowModal={isComFollowModal}
                  merchantDetail={merchantDetail}
                  isPagePDP={isPagePDP}
                  isPageReviews={isPageReviews}
                />
              );
            case "couponFailure":
              return (
                <CouponFailModalDesktop
                  onCloseModal={onCloseModal}
                  couponFailReason={couponFailReason}
                  isComFollowModal={isComFollowModal}
                  isPagePDP={isPagePDP}
                />
              );
            case "commentDetail":
              return (
                <CommentDetailModalDesktop clickEvents={clickEvents} onCloseModal={onCloseModal} comment={modalData} />
              );
          }
          break;
        case "mobile":
          switch (modalType) {
            case "startFollowing":
              return (
                <StartedFollowModalMobile
                  merchantDetail={merchantDetail}
                  onCloseModal={onCloseModal}
                  isComFollowModal={isComFollowModal}
                  isPagePDP={isPagePDP}
                />
              );
            case "coupon":
              return (
                <CouponDealModalMobile
                  onClick={addFollowFunc}
                  onCloseModal={onCloseModal}
                  merchantDetail={merchantDetail}
                  customerDailyCouponsLimit={dailyCouponLimit}
                  loading={loading}
                />
              );
            case "couponSuccess":
              return (
                <CouponSuccessModalMobile
                  onCloseModal={onCloseModal}
                  discountAmount={merchantDetail?.coupon?.discountAmount}
                  loading={loading}
                  isComFollowModal={isComFollowModal}
                  isPagePDP={isPagePDP}
                  isPageReviews={isPageReviews}
                />
              );
            case "couponFailure":
              return (
                <CouponFailModalDesktop
                  onCloseModal={onCloseModal}
                  device={device}
                  couponFailReason={couponFailReason}
                  isComFollowModal={isComFollowModal}
                  isPagePDP={isPagePDP}
                />
              );
            case "rating":
              return <RatingInfoModalMobile merchantDetail={merchantDetail} onCloseModal={onCloseModal} />;
            case "merchantTag":
              return (
                <MerchantTagModalMobile
                  toggleModalVisibility={toggleModalVisibility}
                  onCloseModal={onCloseModal}
                  merchantDetail={merchantDetail}
                />
              );
            case "commentDetail":
              return (
                <CommentDetailModalMobile clickEvents={clickEvents} onCloseModal={onCloseModal} comment={modalData} />
              );
          }
      }
    };
    const hasCloseButton = ["coupon"];
    const closeButtonEnabled = device === "desktop" && hasCloseButton.includes(visibility.modalType);
    return (
      <>
        <Modal
          portalClassName={
            device === "mobile"
              ? styles.portalMobile
              : classNames?.portalClassName
              ? classNames?.portalClassName
              : styles.portalDesktop
          }
          viewType={device === "desktop" ? "DIALOG" : "SHEET"}
          headerClass={styles.modalHeader}
          size={"SMALL"}
          isOpen={visibility.isVisible}
          openingAnimation={true}
          closeButtonEnabled={closeButtonEnabled}
          shouldCloseOnOverlayClick
          scrollLock={true}
          onClose={onCloseModal}
          headerClassName={
            device === "mobile"
              ? styles.modalMobileHeader
              : visibility.modalType === "coupon"
              ? styles.modalCouponHeader
              : styles.modalHeader
          }
          customStyle={styles}
        >
          {modalContent(visibility.modalType, visibility.modalData)}
        </Modal>
      </>
    );
  }
);

export default ModalManager;
