/** Dependencies */
import React from "react";
import cx from "classnames";

/** Styles */
import styles from "./BannerCard.scss";
import { AdultEnums, HB_ADULT } from "../../../../../utils/constants";

/** Utils */
import useCheckMobileScreen from "../../../../../utils/hooks/useCheckMobileScreen";
import { getEscapedUrlByWhiteListForGoogleBot } from "Utils/helpers/url";

const BannerCard = ({ banner, background, clickEventHandler, hbAdultValue, dataLength, isBot }) => {
  const { isMobile } = useCheckMobileScreen();

  const urlToString = () => {
    const ctrlRegex = /https?:\/\/(?:www.)?/g;
    if (banner.url.match(ctrlRegex) !== null) {
      return banner.url;
    } else {
      if (banner.url.includes("www.")) {
        return `https://${banner.url}`;
      } else {
        return `https://www.${banner.url}`;
      }
    }
  };

  const classNameListContainer = {
    [styles.horizontal]: dataLength === 3,
    [styles["vertical-five"]]: dataLength === 5,
    [styles["vertical-four"]]: dataLength === 4
  };

  const imageSizeContainer = {
    3: "/150/",
    4: "/188/",
    5: "/250/"
  };

  const { bannerTitle, bannerMessage, imageUrl } = banner;
  const newImageUrl = imageUrl.replace("/100/", isMobile ? "/136/" : imageSizeContainer[dataLength]);

  const handleBannerImageRenderByIsAdult = () => {
    if (hbAdultValue === HB_ADULT) {
      return <img src={newImageUrl} loading={"lazy"} alt={bannerTitle} />;
    }
    if (
      hbAdultValue !== HB_ADULT &&
      banner?.productAttribute?.length > 0 &&
      banner?.productAttribute !== null &&
      banner?.productAttribute?.every(
        item =>
          item.attributeName === AdultEnums.attributeName && Boolean(item.attributeValue) === AdultEnums.attributeValue
      )
    ) {
      return (
        <div className={styles.adultContainer}>
          <div className={styles.isAdult}>
            <span>18+</span>
          </div>
        </div>
      );
    } else {
      return <img src={newImageUrl} loading={"lazy"} alt={bannerTitle} />;
    }
  };
  return (
    <a
      title={bannerTitle}
      href={getEscapedUrlByWhiteListForGoogleBot({ url: urlToString(), isBot: isBot })}
      onClick={clickEventHandler}
      className={cx(styles.cardRoot, classNameListContainer)}
      style={{ backgroundColor: background.color ?? "#FFFFFF" }}
    >
      <div className={styles.statements}>
        <p className={styles.title}>{bannerTitle}</p>
        <p className={styles.message}>{bannerMessage}</p>
      </div>
      <div className={styles.imageContainer}>{handleBannerImageRenderByIsAdult()}</div>
    </a>
  );
};

export default BannerCard;
