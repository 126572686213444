import { useEffect, useState } from "react";

const useCheckMobileScreen = () => {
  const [isMobile, setIsMobile] = useState();

  useEffect(() => {
    setIsMobile(typeof window?.orientation !== "undefined" && /Mobi/.test(window?.navigator?.userAgent));
  }, []);

  return { isMobile };
};

export default useCheckMobileScreen;
