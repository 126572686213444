/* eslint-disable no-console */
export const newApiError = (error) => {
  const { status, statusText, headers, config, data } = error.response;
  const { url, method, params, headers: configHeaders, baseURL } = config;
  delete config.httpsAgent;
  delete config.httpAgent;
  const apiErrorJson = {
    status,
    statusText,
    headers,
    config: {
      url,
      method,
      params,
      headers: configHeaders,
      baseURL,
    },
    data,
    correlationId: headers["x-request-id"],
    time: new Date(),
  };
  return apiErrorJson;
};

const isApiError = (error) => error && typeof error === "object" && error.response;

export const throwError = (error, context) => {
  if (isApiError(error)) {
    const parsedError = newApiError(error);
    console.log(JSON.stringify(parsedError));
    throw new Error(
      JSON.stringify({
        status: parsedError.status,
        error: JSON.stringify(parsedError.statusText),
        url: parsedError?.config?.url,
        contextUrl: context?.url,
        correlationId: parsedError.correlationId,
        data: JSON.stringify(parsedError.data),
      })
    );
  }

  if (typeof error === "string") {
    throw new Error(JSON.stringify({ error, url: context?.url }));
  }
  if (typeof error === "object" && typeof error.message === "object") {
    const parsedError = JSON.parse(error.message);
    throw new Error(JSON.stringify({ error: parsedError.error, url: context?.url }));
  }
  if (typeof error === "object" && typeof error.message === "string") {
    throw new Error(JSON.stringify({ error: error.message, url: context?.url }));
  }
  throw new Error(error);
};
