/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
/* Utils */
import { appendScriptTag, appendInlineScript, getAllMatches, appendLinkTag, appendScript } from "./utils";

/* Constants */
const INLINE_SCRIPT_REGEX = /(<script[\s\S]*?>)([\s\S]*?)(<\/script>)/gm;
const INLINE_SCRIPT_SRC_REGEX = /<script.*?src="(.*?)"/;
const INLINE_STYLE_REGEX = /<link rel="stylesheet" href="(.*?)"/g;
const INLINE_SCRIPT_CODE_INDEX_IN_ARRAY = 1;
const INLINE_STYLE_CODE_INDEX_IN_ARRAY = 0;
const SCRIPT_SRC_CODE_INDEX_IN_ARRAY = 0;

class VFragment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isStyleLoaded: false,
      isInlineStyleLoaded: false,
      successCssLoaded: 0,
      successInlineCssLoaded: 0
    };
  }

  componentDidMount() {
    // if you use local fragment also add prop component
    const { component } = this.props;
    if (component) {
      this.localOperationContext();
    } else {
      this.fetchOperationContext();
    }
  }

  // eslint-disable-next-line no-empty-function
  fetchOperationContext = (successCallback = () => {}, errorCallback = () => {}) => {
    const { url } = this.props;
    fetch(url)
      .then(res => {
        return res.json();
      })
      .then(
        result => {
          this.handleSuccessFetchOperation(result);
          successCallback(result);
        },
        error => {
          this.handleErrorFetchOperation(error);
          errorCallback(error);
        }
      );
  };
  localOperationContext = () => this.handleSuccessFetchOperation(this.props.component);

  handleSuccessFetchOperation = data => {
    const { isAppendScripts } = this.props;
    this.setState(data);

    this.addInlineScript(data);
    this.addInlineStyle(data);

    if (isAppendScripts) {
      this.addScript(data);
    }

    this.addClientLink(data);
  };

  handleErrorFetchOperation = error => {
    this.setState({
      error
    });
  };

  addScript = res => {
    const commonScript = res.scripts.find(script => script.src.indexOf("common-") > -1);

    const vendorScripts = res.scripts.filter(
      script => script.src.indexOf("vendors") < 0 && script.src.indexOf("client-") > -1
    );

    if (commonScript) {
      appendScriptTag(commonScript);
    }

    // eslint-disable-next-line array-callback-return
    vendorScripts &&
      vendorScripts.length > 0 &&
      vendorScripts.map(vendorScript => {
        if (vendorScript) {
          appendScriptTag(vendorScript, this.addClientScript(res));
        }
      });
  };

  addClientScript = res => {
    // eslint-disable-next-line array-callback-return
    res.scripts.map(script => {
      if (script.src.indexOf("/client-") < 0 && script.src.indexOf("/common-") < 0) {
        // @todo delete timeout
        setTimeout(() => {
          appendScriptTag(script);
        }, 1000);
      }
    });
  };

  addInlineScript = res => {
    const matchedScripts = getAllMatches(res.html, INLINE_SCRIPT_REGEX);
    // eslint-disable-next-line array-callback-return
    Array.from(matchedScripts, script => {
      let scriptSrc = "";
      let inlineScriptCode = "";
      const firstGroup =
        script && script.groups && script.groups.length > 0 ? script.groups[SCRIPT_SRC_CODE_INDEX_IN_ARRAY] : false;

      if (firstGroup && firstGroup.indexOf("src") > 1) {
        const regex = INLINE_SCRIPT_SRC_REGEX;
        scriptSrc = regex.exec(firstGroup) && regex.exec(firstGroup).length > 1 && regex.exec(firstGroup)[1];
        if (scriptSrc) {
          appendScript({
            src: scriptSrc
          });
        }
      } else {
        inlineScriptCode =
          script && script.groups && script.groups.length > 0
            ? script.groups[INLINE_SCRIPT_CODE_INDEX_IN_ARRAY]
            : false;
        if (inlineScriptCode) {
          appendInlineScript(inlineScriptCode);
        }
      }
    });
  };

  addInlineStyle = res => {
    let defaultIndex = 0;
    const matchedStyles = getAllMatches(res.html, INLINE_STYLE_REGEX);
    if (matchedStyles && matchedStyles.length > 0) {
      // eslint-disable-next-line array-callback-return
      Array.from(matchedStyles, (style, index) => {
        const styleHref =
          style && style.groups && style.groups.length > 0 ? style.groups[INLINE_STYLE_CODE_INDEX_IN_ARRAY] : false;
        if (styleHref) {
          appendLinkTag(
            {
              rel: "stylesheet",
              href: styleHref
            },
            () => this.onInlineStyleLoaded(matchedStyles.length),
            () => this.onInlineStyleLoaded(matchedStyles.length)
          );
        }
      });
    } else {
      this.onInlineStyleLoaded(matchedStyles.length, defaultIndex);
    }
  };

  addClientLink = request => {
    if (request.style && request.style.length > 0) {
      // eslint-disable-next-line array-callback-return
      request.style.map((style, index) => {
        appendLinkTag(
          request.style[index],
          () => this.onStyleLoaded(request.style.length),
          () => this.onStyleLoaded(request.style.length)
        );
      });
    } else {
      this.onStyleLoaded(true, true);
    }
  };

  onStyleLoaded = length => {
    this.setState(
      {
        successCssLoaded: this.state.successCssLoaded + 1
      },
      () => {
        if (length || length === 0 || length === this.state.successCssLoaded) {
          this.setState({
            isStyleLoaded: true
          });
        }
      }
    );
  };

  onInlineStyleLoaded = length => {
    this.setState(
      {
        successInlineCssLoaded: this.state.successInlineCssLoaded + 1
      },
      () => {
        if (length || length === 0 || length === this.state.successInlineCssLoaded) {
          this.setState({
            isInlineStyleLoaded: true
          });
        }
      }
    );
  };

  render() {
    const { className, innerRef } = this.props;

    const { html, isStyleLoaded, isInlineStyleLoaded } = this.state;
    const styledLoaded = isStyleLoaded && isInlineStyleLoaded;
    return (
      <div className={className} ref={innerRef} style={{ display: !styledLoaded ? "none" : "block" }}>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
  }
}

VFragment.propTypes = {
  url: PropTypes.string,
  isAppendScripts: PropTypes.bool,
  component: PropTypes.object,
  innerRef: PropTypes.object
};

export default VFragment;
