export const isSameDay = date => {
  return (
    new Date(date).getFullYear() === new Date().getFullYear() &&
    new Date(date).getMonth() === new Date().getMonth() &&
    new Date(date).getDate() === new Date().getDate()
  );
};

export const getFormattedDate = date => {
  const dateWithPattern = new Date(date).toLocaleDateString("tr-TR", {
    ...datePattern(date)
  });
  const day = new Date(date).toLocaleDateString("tr-TR", {
    ...dayPattern(date)
  });
  return [dateWithPattern, day].join(", ");
};

const datePattern = date => {
  if (date.split("-")[0] === new Date().getFullYear().toString()) {
    return {
      month: "long",
      day: "numeric"
    };
  }
  return {
    year: "numeric",
    month: "long",
    day: "numeric"
  };
};
const dayPattern = date => {
  if (date.split("-")[0] === new Date().getFullYear().toString()) {
    return {
      weekday: "long"
    };
  }
  return {
    weekday: "short"
  };
};
