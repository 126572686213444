import React, { useRef } from "react";
import useGetElementHeight from "../../../../utils/hooks/useGetElementHeight";
import styles from "./CouponRow.scss";
import { merchantStoreCouponExternalUrl } from "../../../../services/urls";
import DaVinciService from "../../../../services/davinciService";
import { DAVINCI_PAGE_MERCHANT } from "Utils/constants";
import useOnScreen from "Utils/hooks/useOnScreen";
import VFragmentForwardingRef from "../../VFragment/VFragmentForwardingRef";

const CouponRow = ({ row, index, merchantDetail, backgroundType }) => {
  const couponHeight = useGetElementHeight(`#merchantStoreCoupons-${backgroundType}`);
  const observerRef = useRef();
  const davinci = DaVinciService(merchantDetail, DAVINCI_PAGE_MERCHANT);
  useOnScreen(
    observerRef,
    () => {
      if (couponHeight !== "0px") {
        davinci.viewCouponRowEvent(row, index + 1);
      }
    },
    [couponHeight]
  );

  return (
    <div
      className={couponHeight === "0px" ? "" : styles.merchantStoreCoupons}
      id={`merchantStoreCoupons-${backgroundType}`}
    >
      <VFragmentForwardingRef
        url={`${merchantStoreCouponExternalUrl}/MerchantStoreCoupons?merchantId=${merchantDetail?.id}`}
        name='MerchantStoreCoupons'
        ref={observerRef}
        isAppendScripts
      />
    </div>
  );
};

export default CouponRow;
