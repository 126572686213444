import apiManager from "./apiManager";
import { merchantExternalURL, merchantInternalURL } from "./urls";

export const getMerchantDataById = (merchantId, apiClient, cookies) => {
  return apiManager(merchantExternalURL, apiClient, cookies).GET(`/merchants/${merchantId}`);
};

export const getMerchantDataWithRatingAndCouponByIdExternal = (merchantId, apiClient, cookies) => {
  return apiManager(merchantExternalURL, apiClient, cookies).GET(`/merchants/${merchantId}?rating=true&coupon=true`);
};

export const getMerchantDataByIdInternal = (merchantId, apiClient, cookies) => {
  return apiManager(merchantInternalURL, apiClient, cookies).GET(
    `/merchants/${merchantId}?partners=true&additionalInfo=true`
  );
};

export const getMerchantDataWithRatingAndCouponByIdInternal = (merchantId, apiClient, cookies) => {
  return apiManager(merchantInternalURL, apiClient, cookies).GET(
    `/merchants/${merchantId}?rating=true&coupon=true&partners=true&additionalInfo=true`
  );
};

export const getMerchantDataWithParamsByIdInternal = (merchantId, apiClient, cookies, rating, coupon, partners, additionalInfo) => {
  let params = new URLSearchParams();
  if(rating) params.append("rating", rating)
  if(coupon) params.append("coupon", coupon)
  if(partners) params.append("partners", partners)
  if(additionalInfo) params.append("additionalInfo", additionalInfo)

  return apiManager(merchantInternalURL, apiClient, cookies).GET(
    `/merchants/${merchantId}${params.toString().length === 0 ? "" : "?" + params.toString()}`
  );
};
