import queryString from "querystring";
import { COLORS } from "Utils/constants";

function filterNonExistentObjectValues(object, { shouldFilterEmptyStrings = false } = {}) {
  let filteredObject = {};

  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const value = object[key];

      if (value !== null && typeof value !== "undefined" && (!shouldFilterEmptyStrings || value !== "")) {
        filteredObject[key] = value;
      }
    }
  }

  return filteredObject;
}

function generateQueryString(params, { withPrefix = true, ...queryStringLibOptions } = {}) {
  let result = queryString.stringify(
    filterNonExistentObjectValues(params, {
      shouldFilterEmptyStrings: true
    }),
    queryStringLibOptions
  );

  if (result && withPrefix) {
    result = "?" + result;
  }

  return result;
}

function removeQueryStringFromUrl(url) {
  const partials = url?.split("?");

  return partials?.[0];
}

const getCookie = key => {
  if (typeof window === "undefined") return;
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
};

function setCookie(cname, cvalue, exdays) {
  if (typeof window === "undefined") return;
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

const getColorsByProduct = product =>
  product?.variantList.map(variant => {
    const { displayValue, value } = variant?.properties?.Renk ?? {};

    return {
      displayName: displayValue ?? "",
      value: COLORS?.[value?.toLowerCase()] ?? ""
    };
  });
const handleCheckUrl = url => (url?.startsWith("http") ? url : `https://${url}`);

export {
  generateQueryString,
  getColorsByProduct,
  filterNonExistentObjectValues,
  removeQueryStringFromUrl,
  getCookie,
  setCookie,
  handleCheckUrl
};
