import React, { useCallback, useEffect, useRef, useState } from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import styles from "./MobilePlayer.scss";
import { MobileModalPlayIcon, MobilePauseIcon, MobilePlayIcon } from "../../../../../../assets/components";
import { Slider } from "./Slider";
import { timeToTimeString } from "./utils/timeToTimeString";
import ChevronRight from "../../../../../../assets/components/chevronRight";
import { handleCheckUrl } from "Utils/helpers/utils";
import useLazyLoad from "Utils/hooks/useLazyLoad";
import ProductTag from "./ProductTag/loadable";

const MobilePlayer = ({
  source,
  poster,
  onShowModal,
  showModal,
  title,
  url,
  flat,
  rowLength,
  clickEvent,
  showProductRedirectButton,
  products,
  bannerIndex,
  rowIndex,
  row,
  merchantDetail,
  onTagClickEvent,
  onUrlClick
}) => {
  const [player, isIntersecting] = useLazyLoad();
  const interval = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [progress, setProgress] = useState(0);
  const [maxTime, setMaxTime] = useState(0);
  const [playing, setPlaying] = useState(false);
  const [hover, setHover] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleTimeChange = useCallback((time, offsetTime) => {
    if (!player.current?.currentTime) {
      return;
    }

    player.current.currentTime = time / 1000;
    setCurrentTime(time);
  }, []);

  const handlePlay = () => {
    interval.current = setInterval(() => {
      setCurrentTime((player.current?.currentTime || 0) * 1000);
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(interval.current);
  };

  const handleDataLoaded = () => {
    setMaxTime((player.current?.duration || 0) * 1000);
  };

  const handleProgress = () => {
    const buffer = player?.current?.buffered;

    if (((buffer?.length > 0 && player.current?.duration) || 0) > 0) {
      let currentBuffer = 0;
      const inSeconds = player.current?.currentTime || 0;

      for (let i = 0; i < buffer.length; i++) {
        if (buffer.start(i) <= inSeconds && inSeconds <= buffer.end(i)) {
          currentBuffer = i;
          break;
        }
      }

      setProgress(buffer.end(currentBuffer) * 1000 || 0);
    }
  };
  useEffect(() => {
    if (!player) {
      return;
    }

    setMaxTime((player.current?.duration || 0) * 1000);
    player.current?.addEventListener("play", handlePlay);
    player.current?.addEventListener("pause", handlePause);
    player.current?.addEventListener("loadeddata", handleDataLoaded);
    player.current?.addEventListener("progress", handleProgress);
  }, [player]);
  const maxTimeString = timeToTimeString(maxTime, maxTime, 0, "0:", "00:");
  const currentTimeString = timeToTimeString(maxTime, currentTime, 0, "0:", "00:");

  useEffect(() => {
    if (playing || hover) {
      const timer = setTimeout(() => {
        setHover(false);
      }, 1000 * 2);
      return () => clearTimeout(timer);
    }
  }, [playing, hover]);
  return (
    <div
      className={cx(styles.Container, {
        [styles.MinContainer]: !showModal,
        [styles.MaxContainer]: rowLength > 1 && showModal,
        [styles.FitContainer]: rowLength === 1 && !showModal
      })}
      onClick={() => setHover(!hover)}
    >
      {showProductRedirectButton !== "modal" && (
        <img loading='lazy' className={styles.VideoThumb} src={poster} alt={title || "Video Banner"} />
      )}

      {showProductRedirectButton === "modal" && (
        <>
          <video
            ref={player}
            className={cx(styles.Video, {
              [styles.VideoDefault]: !flat,
              [styles.VideoFlat]: flat
            })}
            poster={poster}
            preload='metadata'
            onLoadedMetadata={() => setLoading(false)}
            onPlay={() => setPlaying(true)}
            onPause={() => setPlaying(false)}
            playsInline
            webkit-playsinline
          >
            {isIntersecting && <source src={source} type='video/mp4' />}
          </video>
          <div className={styles.Bottom}>
            {!loading && playing && hover && (
              <div className={styles.SliderWrapper}>
                <span className={styles.SliderBlock}>{currentTimeString}</span>
                <Slider
                  max={maxTime}
                  currentTime={currentTime}
                  bufferTime={progress}
                  onChange={handleTimeChange}
                  limitTimeTooltipBySides
                  secondsPrefix='00:'
                  minutesPrefix='0:'
                />
                <span className={styles.SliderBlock}>{maxTimeString}</span>
              </div>
            )}

            {title && <span className={styles.SingleTitle}>{title}</span>}
            {!products && (
              <>
                {(url || title) && (
                  <div className={styles.Context} style={{ justifyContent: !!title ? "space-between" : "center" }}>
                    <span className={styles.Title}>{title}</span>
                    <a
                      href={handleCheckUrl(url)}
                      target='_blank'
                      onClick={() => onUrlClick?.()}
                      title='Ürüne git'
                      className={styles.WithModalMobileProductRedirectButton}
                    >
                      <span>Ürüne git</span>
                      <ChevronRight color='#FF6000' />
                    </a>
                  </div>
                )}
              </>
            )}
            {products && (
              <ProductTag
                products={products}
                isMobile
                bannerIndex={bannerIndex}
                rowIndex={rowIndex}
                row={row}
                merchantDetail={merchantDetail}
                clickEvent={onTagClickEvent}
              />
            )}
          </div>
        </>
      )}

      {loading && <span className={[styles.Spinner, styles.Animated].join(" ")} />}
      {!loading && !playing && (
        <>
          {showProductRedirectButton !== "modal" && (
            <MobilePlayIcon
              role='button'
              className={styles.PlayIcon}
              onClick={() => {
                showModal ? onShowModal() : player?.current?.play();
                clickEvent();
              }}
            />
          )}
          {showProductRedirectButton === "modal" && (
            <MobileModalPlayIcon
              role='button'
              className={styles.ModalPlayIcon}
              onClick={() => {
                player?.current?.play();
              }}
            />
          )}
        </>
      )}

      {!loading && playing && hover && (
        <MobilePauseIcon role='button' className={styles.PauseIcon} onClick={() => player?.current?.pause()} />
      )}
    </div>
  );
};

MobilePlayer.propTypes = {
  source: PropTypes.string.isRequired,
  poster: PropTypes.string.isRequired,
  onShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  title: PropTypes.string,
  url: PropTypes.string,
  flat: PropTypes.bool,
  rowLength: PropTypes.number,
  clickEvent: PropTypes.func
};
export default MobilePlayer;
