import appConfig from "../appConfig";

export const merchantExternalURL = appConfig.services.merchantContentApi.client;
export const merchantInternalURL = appConfig.services.merchantContentApi.serverUrl;

export const couponExternalUrl = appConfig.services.campaign.client;
export const couponInternalUrl = appConfig.services.campaign.serverUrl;

export const storefrontMainApiInternalURL = appConfig.services.storefrontMain.serverUrl;
export const storefrontMainApiExternalURL = appConfig.services.storefrontMain.client;

export const mordorApiInternalURL = appConfig.services.product.serverUrl;

export const moriaApiInternalURL = appConfig.services.moria.serverUrl;
export const moriaApiExternalURL = appConfig.services.moria.client;

export const merchantStoreCouponExternalUrl = appConfig.services.coupon.client;

export const hermesVoltranExternalUrl = appConfig.services.hermesVoltran.client;
