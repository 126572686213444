import React from "react";
import styles from "./VisualCommentCard.scss";
import Rate from "@cosmos/rate";
import { IconStar } from "../../../../../../assets/components";
import OneStarRating from "../../../../OneStarRating/OneStarRating";
import { replaceBrandOnProductName } from "Utils/helpers/productName";
import GlassyCard from "../../../../GlassyCard/GlassyCard";
import {getImageUrl} from "../../utils";

const VisualCommentCard = ({ comment, onClick }) => {
  const getCardBackgroundImage = () => {
    if ((!comment.media || comment.media?.length === 0) && comment.video?.length > 0)
      return comment.video[0].thumbnailUrl;

    return getImageUrl(comment.media[0].url, 438);
  };
  return (
    <div className={styles.wrapper} onClick={onClick}>
      <div className={styles.image}>
        <img src={getCardBackgroundImage()} alt='visual-comment-img' />
      </div>
      <GlassyCard classname={styles.reviewCard}>
        <div className={styles.review}>
          {comment.review?.content ? <div className={styles.comment}>{comment.review?.content}</div> : null}
          <div className={styles.info}>
            <div className={styles.commentator}>
              <span>{comment.customer.name}</span>
              <span>{comment.customer.surname}</span>
            </div>
            <div className={styles.rating}>
              <Rate value={comment.star} greyEmptyStar character={IconStar} size='small' disabled />
            </div>
          </div>
        </div>
      </GlassyCard>
      <GlassyCard classname={styles.productCard}>
        <div className={styles.product}>
          <div className={styles.productIcon}>
            <img src={comment.product.imageUrl?.replace("{size}", 240)} alt='brand-icon' />
          </div>
          <div className={styles.productName}>
            {replaceBrandOnProductName({ brand: comment.product.brand, productName: comment.product.name })}
          </div>
          <OneStarRating rate={comment.product.average} totalRate={comment.product.userContentCount} />
        </div>
      </GlassyCard>
    </div>
  );
};

export default VisualCommentCard;
